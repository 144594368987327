/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Adobe_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -331px -72px;
  width: 52px;
  height: 71px;
}
.icon-FOX_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -242px 0px;
  width: 88px;
  height: 71px;
}
.icon-Legendary_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px -141px;
  width: 120px;
  height: 57px;
}
.icon-LucasFilm_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -272px;
  width: 120px;
  height: 38px;
}
.icon-MGM_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px -72px;
  width: 120px;
  height: 64px;
}
.icon-Marvel_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -90px -202px;
  width: 120px;
  height: 48px;
}
.icon-Mattel_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -242px -72px;
  width: 71px;
  height: 71px;
}
.icon-NBC_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px 0px;
  width: 120px;
  height: 71px;
}
.icon-Netflix_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px 0px;
  width: 120px;
  height: 71px;
}
.icon-RiotGames_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -202px;
  width: 89px;
  height: 69px;
}
.icon-STX_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -141px;
  width: 120px;
  height: 60px;
}
.icon-TonyBennett_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px -272px;
  width: 120px;
  height: 36px;
}
.icon-Universal_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -72px;
  width: 120px;
  height: 68px;
}
.icon-WarnerBros_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -331px 0px;
  width: 68px;
  height: 71px;
}
.icon-sony_pictures {
  background-image: url(../images/sprite-mobile.png);
  background-position: -331px -144px;
  width: 42px;
  height: 71px;
}
