/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Adobe_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -331px -72px;
  width: 52px;
  height: 71px;
}
.icon-FOX_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -242px 0px;
  width: 88px;
  height: 71px;
}
.icon-Legendary_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px -141px;
  width: 120px;
  height: 57px;
}
.icon-LucasFilm_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -272px;
  width: 120px;
  height: 38px;
}
.icon-MGM_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px -72px;
  width: 120px;
  height: 64px;
}
.icon-Marvel_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -90px -202px;
  width: 120px;
  height: 48px;
}
.icon-Mattel_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -242px -72px;
  width: 71px;
  height: 71px;
}
.icon-NBC_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px 0px;
  width: 120px;
  height: 71px;
}
.icon-Netflix_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px 0px;
  width: 120px;
  height: 71px;
}
.icon-RiotGames_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -202px;
  width: 89px;
  height: 69px;
}
.icon-STX_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -141px;
  width: 120px;
  height: 60px;
}
.icon-TonyBennett_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -121px -272px;
  width: 120px;
  height: 36px;
}
.icon-Universal_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: 0px -72px;
  width: 120px;
  height: 68px;
}
.icon-WarnerBros_Logo {
  background-image: url(../images/sprite-mobile.png);
  background-position: -331px 0px;
  width: 68px;
  height: 71px;
}
.icon-sony_pictures {
  background-image: url(../images/sprite-mobile.png);
  background-position: -331px -144px;
  width: 42px;
  height: 71px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  background-color: #F2EFEF;
  color: #535353;
  font-family: montserrat, sans-serif;
  font-weight: 400;
}
body.no-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
}
@media only screen and (max-width: 900px) {
  body.no-scroll {
    position: fixed;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul li {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 80px;
  padding: 18px 0;
  margin: 0;
}
@media only screen and (max-width: 680px) {
  h1 {
    font-size: 60px;
  }
}
h2,
.h2 {
  font-size: 30px;
}
@media only screen and (max-width: 900px) {
  h2,
  .h2 {
    font-size: 36px;
  }
}
h3 {
  font-size: 26px;
  margin: 30px auto;
}
@media only screen and (min-width: 901px) {
  h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 900px) {
  h3 {
    font-size: 30px;
  }
}
h4 {
  font-size: 24px;
}
@media only screen and (max-width: 900px) {
  h4 {
    font-size: 36px;
  }
}
.ai-em {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  font-style: normal;
}
p {
  line-height: 1.8em;
  margin: 0px;
  font-size: 16px;
}
p.home-content {
  font-size: 22px;
}
@media only screen and (max-width: 900px) {
  p {
    font-size: 30px;
  }
  p.home-content {
    font-size: 30px;
  }
}
a {
  text-decoration: none;
  color: #01AA9F;
}
a:visited {
  color: #01AA9F;
}
a.underline {
  text-decoration: underline;
}
input[type="checkbox"] {
  display: none;
}
.double-letter-space {
  letter-spacing: 8px;
}
.font-weight-bold {
  font-family: montserrat, sans-serif;
  font-weight: 700;
}
.font-weight-medium {
  font-family: montserrat, sans-serif;
  font-weight: 500;
}
.font-weight-light {
  font-family: montserrat, sans-serif;
  font-weight: 400;
}
.align-center {
  text-align: center;
}
.vertical-align-top {
  vertical-align: top !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.vertical-align-bottom {
  vertical-align: bottom !important;
}
.color-ai-teal {
  color: #01AA9F;
}
.color-ai-black {
  color: black;
}
.color-ai-white {
  color: white;
}
button {
  position: relative;
  margin: 0 auto;
  border: none;
  overflow: visible;
  background: transparent;
  -webkit-appearance: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  font-family: montserrat, sans-serif;
}
button.btn-1 {
  line-height: 38px;
  transition: all 0.3s;
  width: 160px;
  height: 36px;
}
button.btn-1 span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  color: #01AA9F;
  transition: all 0.3s;
  transform: scale(1, 1);
  z-index: 1;
}
button.btn-1 span a {
  transition: all 0.3s;
  color: #01AA9F !important;
}
button.btn-1::before,
button.btn-1::after {
  content: '';
  position: absolute;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
button.btn-1::before {
  opacity: 0;
  background: #01AA9F;
  transform: scale(1, 0.1);
}
button.btn-1::after {
  transition: all 0.3s;
  border: 2px solid #01AA9F;
}
button.btn-1:hover span {
  color: white;
}
button.btn-1:hover span a {
  color: white !important;
}
button.btn-1:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
button.btn-1:hover::after {
  transform: scale(1, 0.1);
  opacity: 0;
}
button.btn-1.round::before {
  border-radius: 20px;
}
button.btn-1.round::after {
  border-radius: 20px;
}
svg .ai-svg-wrapper path {
  transition: fill 0.2s;
  fill: #01AA9F;
}
svg .ai-svg-wrapper circle {
  transition: fill 0.2s;
  fill: #01AA9F;
}
svg .ai-svg-wrapper polygon {
  fill: #01AA9F;
}
svg .ai-svg-wrapper rect {
  fill: #01AA9F;
}
svg use svg {
  fill: inherit;
}
.loader {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .loader-svg {
  animation: rotate 3s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.loader .loader-svg .path {
  stroke: #01AA9F;
  stroke-linecap: round;
  animation: dash-rotate 1.5s infinite cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loader .loader-logo {
  /*transform: translate(-8%, 14%) scale(0.60);*/
  width: 48px;
  height: 35.23px;
  position: absolute;
  left: 12%;
  top: 26%;
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes dash-rotate {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.ai-svg-hover {
  transition: background-color 0.2s;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  font-size: 0;
}
.ai-svg-hover:hover {
  background-color: #01AA9F;
}
.ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
.ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle {
  fill: white;
}
.ai-hover {
  position: relative;
  cursor: pointer;
  margin: 0;
}
.ai-hover::after {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  width: 0;
  bottom: -3px;
  left: 50%;
  background-color: #01AA9F;
  transform: translateX(-50%);
  transition: width 0.3s;
}
.ai-hover:hover::after {
  width: 100%;
}
.ai-hover.active::after {
  width: 100% !important;
}
.alt-text {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  text-indent: 5px;
  white-space: nowrap;
}
.ai-parallax {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.ai-parallax .parallax-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: auto;
}
#page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 200;
}
.icon-scroll {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 70px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 30px;
  opacity: 0.5;
}
.icon-scroll:before {
  position: absolute;
  left: 50%;
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
.grid-container {
  position: relative;
  margin: 0 auto 20px;
  padding: 0;
  width: 100%;
  overflow: hidden;
  /*
    @media only screen and (min-width: @screen-min) {
        .grid-item,
        .grid-sizer {
            width: 50%;
        }
    }
    @media only screen and (min-width: 1024px) {
        .grid-item,
        .grid-sizer {
            width: 33.33%;
        }
        .grid-item.double_size,
        .grid-sizer.double_size {
            width: 66.6%;
        }
    }
    @media only screen and (min-width: 1420px) {
        .grid-item,
        .grid-sizer {
            width: 33.33%;
        }
        .grid-item.double_size,
        .grid-sizer.double_size {
            width: 66.6%;
        }
    }
    @media only screen and (min-width: 1800px) {
        .grid-item,
        .grid-sizer {
            width: 25%;
        }
        .grid-item.double_size,
        .grid-sizer.double_size {
            width: 50%;
        }
    }
    */
}
.grid-container:after {
  content: '';
  display: block;
  clear: both;
}
.grid-container.load-completed .loader {
  display: none;
}
.grid-container .grid-sizer {
  width: 50%;
}
.grid-container .grid-item {
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.grid-container .grid-item img {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
.grid-container .grid-item .post-subtitle {
  display: none;
}
.grid-container .grid-item.active {
  opacity: 1;
  cursor: pointer;
}
.grid-container .grid-item.hide {
  display: none;
}
.grid-container .grid-item.filter-hide {
  display: none;
}
.grid-container .grid-item::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3%;
  background-color: #01AA9F;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease-out;
}
.grid-container .grid-item:hover::after {
  width: 100%;
}
.for-mobile {
  display: none;
}
.carousel-arrow {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  margin-top: -40px;
  opacity: 0;
  cursor: pointer;
  z-index: 90;
}
.carousel-arrow .icon-center {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}
.carousel-arrow.left {
  left: 0;
}
.carousel-arrow.left .icon-center {
  transform: rotate(180deg);
}
.carousel-arrow.right {
  right: 0;
}
.float-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
body .page-wrapper {
  display: none;
}
body .img-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  opacity: 1;
}
body .img-hover:hover {
  opacity: 0;
}
body #main-logo {
  position: absolute;
  width: 66px;
  height: 66px;
  left: 30px;
  top: 20px;
  padding: 0;
  margin: 0;
  z-index: 103;
  cursor: pointer;
  font-size: 0;
}
body #main-logo a,
body #main-logo img {
  display: block;
  width: 66px;
  height: 66px;
}
body #main-logo.home {
  display: block;
}
body #top-bar {
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 102;
  border-bottom: 0;
}
body #top-bar #top-bar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0 30px;
}
body #top-bar #top-bar-wrapper.dark {
  background-color: #1a222d;
}
body #top-bar #top-bar-wrapper .logo {
  position: relative;
  height: 39px;
  width: 206px;
  margin: 20px 0 0 72px;
  float: left;
  cursor: pointer;
  opacity: 0;
}
body #top-bar #top-bar-wrapper .logo img {
  width: 100%;
}
body #top-bar #top-bar-wrapper .logo svg .ai-svg-wrapper path {
  fill: #535353;
}
body #top-bar #top-bar-wrapper .nav-button {
  display: none;
  position: absolute;
  width: 42px;
  height: 68px;
  top: 0;
  right: 34px;
  line-height: 17px;
  padding-top: 12px;
  cursor: pointer;
  transition: 1s;
  text-align: right;
}
body #top-bar #top-bar-wrapper .nav-button .line {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 70%;
  border-top: 5px solid #01AA9F;
  transition: 0.5s ease;
}
body #top-bar #top-bar-wrapper .nav-button .line:first-child {
  width: 100%;
  transform: translateY(4px);
}
body #top-bar #top-bar-wrapper .nav-button .line:last-child {
  width: 40%;
  transform: translateY(-4px);
}
body #top-bar #top-bar-wrapper .nav-button.open {
  text-align: center;
}
body #top-bar #top-bar-wrapper .nav-button.open .line {
  left: 0;
}
body #top-bar #top-bar-wrapper .nav-button.open .line:first-child {
  transform: translateY(17px) rotateZ(45deg);
  width: 100%;
}
body #top-bar #top-bar-wrapper .nav-button.open .line:last-child {
  transform: translateY(-17px) rotateZ(-45deg);
  width: 100%;
}
body #top-bar #top-bar-wrapper .nav-button.open .line:nth-child(2) {
  width: 1px;
}
@media only screen and (max-width: 900px) {
  body #top-bar #top-bar-wrapper .nav-button {
    display: block;
  }
}
body #top-bar #top-bar-wrapper .top-menu {
  float: right;
  height: 100%;
}
body #top-bar #top-bar-wrapper .top-menu ul {
  height: 100%;
}
body #top-bar #top-bar-wrapper .top-menu ul::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
body #top-bar #top-bar-wrapper .top-menu ul li {
  display: inline-block;
  color: #535353;
  font-size: 20px;
  font-weight: 700;
  vertical-align: middle;
  margin: 0 10px;
}
@media only screen and (max-width: 900px) {
  body #top-bar #top-bar-wrapper .top-menu {
    display: none;
  }
}
body #top-bar #top-bar-wrapper .social-linkouts {
  width: 190px;
  height: 80px;
  float: right;
}
body #top-bar #top-bar-wrapper .social-linkouts ul {
  width: 100%;
  height: 80px;
}
body #top-bar #top-bar-wrapper .social-linkouts ul li {
  position: relative;
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}
body #top-bar #top-bar-wrapper .social-linkouts ul li:first-child {
  margin-right: 0;
}
body #top-bar #top-bar-wrapper .social-linkouts ul li a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 900px) {
  body #top-bar #top-bar-wrapper .social-linkouts {
    display: none;
  }
}
body #top-bar #top-bar-wrapper #filter-trigger {
  display: none;
  float: right;
  width: 30px;
  height: 30px;
  margin: 0 0 0 30px;
  cursor: pointer;
  padding: 35px 0px 15px;
}
body #top-bar #top-bar-wrapper #filter-trigger span {
  display: block;
}
body #top-bar #top-bar-wrapper #filter-trigger .grid {
  width: 9px;
  height: 9px;
  color: #00aa9f;
  background-color: #00aa9f;
  /* Not in use when the colors are specified below */
  transition: 0.5s;
}
body #top-bar #top-bar-wrapper #filter-trigger.close .grid {
  transform: rotate3d(0, 0, 1, -45deg) scale3d(0.7, 0.7, 0.7);
}
body #top-bar #top-bar-wrapper #filter-trigger.rearrange .grid {
  box-shadow: -11.25px -11.25px, 0 -11.25px, 11.25px -11.25px, -11.25px 0, 11.25px 0, -11.25px 11.25px, 0 11.25px, 11.25px 11.25px;
}
body #top-bar #top-bar-wrapper #filter-trigger.rearrange.close .grid {
  box-shadow: 0 -9px, 0 -18px, 9px 0, -18px 0, 18px 0, -9px 0, 0 18px, 0 9px;
}
body #top-bar #top-bar-wrapper.work {
  /*
                .social-linkouts {
                    display: none;
                }
                #filter-trigger {
                    display: inline-block;
                }
                */
}
body #top-bar #top-bar-wrapper.work .logo {
  opacity: 1 !important;
}
body #top-bar #top-bar-wrapper.detail {
  /*
                .social-linkouts {
                    display: none;
                }
                .button.close {
                    display: block;
                }
                */
}
body #top-bar #top-bar-wrapper.detail .logo {
  opacity: 1 !important;
}
body #top-bar #top-bar-wrapper.social .logo {
  opacity: 1 !important;
}
body #top-bar #top-bar-wrapper.permapage {
  /*
                .social-linkouts {
                    display: none;
                }
                .button.close {
                    display: block;
                }
                */
}
body #top-bar #top-bar-wrapper.permapage .logo {
  opacity: 1 !important;
}
@media only screen and (max-width: 900px) {
  body #top-bar #top-bar-wrapper > * {
    transform: scale(0.9);
  }
}
body #top-bar.home #top-bar-wrapper {
  top: -80px;
}
body #page-home {
  display: block;
}
body #page-home section {
  padding-top: 20px;
  padding-bottom: 20px;
}
body #page-home section .moveup {
  opacity: 0;
}
body #page-home .button-wrapper {
  display: none;
  width: 100%;
  padding: 0;
}
body #page-home .button-wrapper .button-padding {
  width: 100%;
  padding: 0 30px;
  text-align: center;
}
body #page-home .home-content {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}
@media only screen and (min-width: 901px) {
  body #page-home .home-content {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
body #page-home button.home-button {
  font-family: montserrat, sans-serif;
  font-weight: 400;
  color: #01AA9F;
  width: 100%;
  border: 1px solid white;
  padding: 10px 0;
  margin: 10px auto 30px;
}
@media only screen and (min-width: 901px) {
  body #page-home button.home-button {
    display: inline-block;
    max-width: 400px;
  }
}
body #page-home section#marquee {
  position: relative;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
}
body #page-home section#marquee #marquee-container {
  position: relative;
  padding: 0 30px;
  height: 400px;
  overflow: hidden;
  margin-top: 0;
}
body #page-home section#marquee #marquee-container .marquee-template {
  background-color: #1A222E;
  position: relative;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  margin: 15px 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /*
                        @media only screen and (max-width: @screen-max) {
                            top: 50px;
                            opacity: 0;
                            &.loaded {
                                top: 0;
                                opacity: 1;
                            }
                        }
                        */
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-image {
  line-height: 0;
  min-height: 200px;
  overflow: hidden;
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-image img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 900px) {
  body #page-home section#marquee #marquee-container .marquee-template .marquee-image {
    height: 100%;
  }
  body #page-home section#marquee #marquee-container .marquee-template .marquee-image img {
    position: absolute;
    width: auto;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-info {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 30px;
  text-align: left;
  padding-bottom: 5px;
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-title,
body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-subtitle {
  color: white;
  opacity: 1;
  transition: opacity 0.5s;
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-title {
  font-size: 36px;
  text-transform: uppercase;
  padding: 3px 0;
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-subtitle {
  font-size: 26px;
  line-height: 1.2em;
  margin-bottom: 10px;
  text-transform: uppercase;
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-title,
body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-subtitle {
  opacity: 0;
}
body #page-home section#marquee #marquee-container .marquee-template .marquee-info.active .marquee-title,
body #page-home section#marquee #marquee-container .marquee-template .marquee-info.active .marquee-subtitle {
  opacity: 1;
}
@media only screen and (max-width: 900px) {
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info {
    left: 0;
    text-align: center;
  }
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-title,
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info .marquee-subtitle {
    display: none;
  }
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info .btn-1 {
    display: inline-block;
    width: 320px;
    height: 70px;
    font-size: 28px;
    line-height: 70px;
    bottom: 40px;
  }
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info .btn-1.round::before {
    border-radius: 40px;
  }
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info .btn-1.round::after {
    border-radius: 40px;
  }
}
@media only screen and (max-width: 900px) {
  body #page-home section#marquee #marquee-container .marquee-template .marquee-info .btn-1 {
    bottom: 0;
  }
}
body #page-home section#marquee #marquee-container .marquee-controls {
  display: none;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
body #page-home section#marquee #marquee-container .marquee-controls .marquee-control-button {
  display: inline-block;
  width: 30px;
  overflow: hidden;
  font-size: 90px;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  color: #C9C9C9;
  transition: color 200ms ease-out;
}
body #page-home section#marquee #marquee-container .marquee-controls .marquee-control-button.active {
  color: #535353;
}
body #page-home section#marquee #marquee-container .marquee-controls .marquee-control-button:hover {
  color: #535353;
}
body #page-home section#marquee #marquee-container .marquee-controls .marquee-control-button:last-child {
  margin-right: 0;
}
body #page-home section#marquee #marquee-container.carousel-container {
  padding: 0;
  width: 100%;
}
body #page-home section#marquee #marquee-container.carousel-container .marquee-template {
  display: none;
  position: absolute;
  box-shadow: none;
  margin: 0;
  width: 100%;
}
body #page-home section#marquee #marquee-container.carousel-container .marquee-controls {
  display: block;
}
@media only screen and (max-width: 900px) {
  body #page-home section#marquee #marquee-container.carousel-container .marquee-controls {
    display: none;
  }
}
body #page-home section#marquee .carousel-arrow {
  display: block;
  width: 36px;
  height: 36px;
  top: auto;
  margin-top: auto;
  opacity: 1;
  border-radius: 35px;
  border: 2px solid #00aa9f;
  transition: 0.25s background;
}
body #page-home section#marquee .carousel-arrow .icon-center {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -18px;
}
body #page-home section#marquee .carousel-arrow.left {
  left: 30px;
  top: 50%;
  margin-top: -18px;
}
body #page-home section#marquee .carousel-arrow.right {
  right: 30px;
  top: 50%;
  margin-top: -18px;
}
@media only screen and (max-width: 900px) {
  body #page-home section#marquee .carousel-arrow {
    display: block;
    width: 70px;
    height: 70px;
    bottom: 30px;
  }
  body #page-home section#marquee .carousel-arrow .icon-center {
    width: 70px;
    height: 70px;
    margin: -35px 0 0 -35px;
  }
  body #page-home section#marquee .carousel-arrow.left {
    left: 50%;
    margin-left: -260px;
    top: auto;
  }
  body #page-home section#marquee .carousel-arrow.right {
    right: 50%;
    margin-right: -260px;
    top: auto;
  }
}
body #page-home section#marquee .carousel-arrow:hover {
  background-color: #00aa9f;
}
body #page-home section#marquee .carousel-arrow:hover .ai-arrow-triangle {
  fill: white;
}
body #page-home section.static-mode #marquee-container {
  height: auto !important;
}
body #page-home section.static-mode .button-wrapper {
  display: block;
}
body #page-home section#about-hello {
  position: relative;
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}
body #page-home section#about-hello h1 {
  position: relative;
  padding: 0;
  font-size: 0;
  width: 100%;
  padding: 5% 30px;
}
body #page-home section#about-hello h1 img {
  display: block;
  margin: 0 auto;
  width: 100%;
}
body #page-home section#about-hello h1.hello-from-ai {
  padding: 0;
  text-align: center;
  overflow: hidden;
}
body #page-home section#about-hello h1.hello-from-ai img {
  width: 100%;
  min-width: 980px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}
body #page-home section#about-hello h1.hello-from-ai img#img-hello-off {
  position: relative;
}
body #page-home section#about-hello h1.hello-from-ai img#img-hello-on {
  position: absolute;
  top: 0;
  opacity: 0;
}
body #page-home section#about-hello h1.hello-from-ai img#img-hello-on.start-neon#img-hello-on {
  animation: flicker 5s forwards 0.3s step-end;
}
body #page-home section#about-hello .h2 {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 10vw;
}
@media only screen and (max-width: 900px) {
  body #page-home section#about-hello h1 {
    margin: 0 auto;
  }
  body #page-home section#about-hello h1 img#img-who-we-are {
    margin: 20px auto 30px;
    width: 70%;
  }
}
@keyframes flicker {
  0% {
    opacity: 1;
  }
  3% {
    opacity: 0.4;
  }
  6% {
    opacity: 1;
  }
  7% {
    opacity: 0.4;
  }
  8% {
    opacity: 1;
  }
  9% {
    opacity: 0.4;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
body #page-home section#about-who-we-are {
  background-color: white;
}
body #page-home section#about-who-we-are .who-are-we-canvas {
  width: 100%;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper {
  position: relative;
  width: 344px;
  height: 584px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: -100px 0 -200px;
  transform-origin: left center;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper .backdrop {
  position: absolute;
  width: 141px;
  height: 141px;
  top: 0;
  left: 0;
  transform: scale(2) translate(11%, 10%);
  transform-origin: left top;
  transition: transform 3s;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper .backdrop-bg {
  position: absolute;
  width: 1165px;
  height: 355px;
  left: 50%;
  top: 50%;
  transform: scale(0.15) translate(-50%, -50%);
  transform-origin: left top;
  transition: transform 3s;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper .backdrop-costume {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper .backdrop-costume .backdrop {
  overflow: hidden;
  border-radius: 50%;
  filter: blur(4px);
  background-color: white;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper .backdrop-costume .img-costume {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(2) rotate(-30deg) translate(-32%, 10.6%);
  transform-origin: left top;
  transition: transform 3s;
}
body #page-home section#about-who-we-are .who-are-we-canvas .backdrop-wrapper .backdrop-costume .backdrop-bg-mimic {
  position: absolute;
  width: 1165px;
  height: 355px;
  left: 50%;
  top: 50%;
  transform: scale(0.15) translate(-50%, -50%);
  transform-origin: left top;
  transition: transform 3s;
}
body #page-home section#about-individually-we-are {
  position: relative;
  background-color: #01AA9F;
  background-image: url('../images/wave_top.jpg');
  background-position: left top;
  background-repeat: repeat-x;
  padding: 60px 0 0;
  overflow: visible;
}
body #page-home section#about-individually-we-are.start {
  animation: bg-scroll 20s linear infinite;
}
body #page-home section#about-individually-we-are .image-comp {
  position: absolute;
  transform: scale(0.5);
  transform-origin: left top;
}
body #page-home section#about-individually-we-are .image-comp.icon-idea {
  top: 10px;
  left: -130px;
}
body #page-home section#about-individually-we-are .image-comp.icon-rod {
  bottom: -70px;
  left: -70px;
}
body #page-home section#about-individually-we-are .image-comp.icon-pen {
  top: -40px;
  right: -220px;
}
body #page-home section#about-individually-we-are .image-comp.icon-mag {
  bottom: -100px;
  right: -115px;
}
@media only screen and (max-width: 950px) {
  body #page-home section#about-individually-we-are .image-comp {
    display: none;
  }
}
body #page-home section#about-individually-we-are #bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body #page-home section#about-individually-we-are #bubbles .bubble {
  position: absolute;
  width: 13.33333333vw;
  height: 13.33333333vw;
  will-change: transform;
  animation: bubblerise 2s forwards linear;
  opacity: 0;
}
body #page-home section#about-individually-we-are #bubbles .bubble .bubblewobble {
  width: 100%;
  height: 100%;
  background-image: url('../images/bubble.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /*
                            will-change: transform;
                            animation: bubblewobble 0.44s infinite linear;
                            */
}
@keyframes bubblerise {
  0% {
    bottom: -20%;
    opacity: 0;
  }
  25% {
    bottom: 10%;
    opacity: 0.8;
  }
  50% {
    bottom: 40%;
    opacity: 1;
  }
  75% {
    bottom: 70%;
    opacity: 0.8;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}
@keyframes bubblewobble {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 4px;
  }
}
body #page-home section#about-individually-we-are .name-changer {
  position: relative;
  width: 657px;
  height: 160px;
  margin: 20px auto;
  background-image: url('../images/dotted_frame.png');
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
body #page-home section#about-individually-we-are .name-changer .name-changer-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
body #page-home section#about-individually-we-are .name-changer .name-changer-wrapper .name-changer-item {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 657px;
  height: 160px;
  line-height: 160px;
  font-size: 80px;
  color: white;
  text-align: center;
}
body #page-home section#about-individually-we-are .name-changer .name-changer-wrapper .name-changer-item img {
  height: 80%;
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
@media only screen and (max-width: 720px) {
  body #page-home section#about-individually-we-are .name-changer {
    width: 80%;
    margin: 0 10%;
  }
  body #page-home section#about-individually-we-are .name-changer .name-changer-wrapper .name-changer-item {
    width: 100%;
  }
  body #page-home section#about-individually-we-are .name-changer .name-changer-wrapper .name-changer-item img {
    width: 60%;
    height: auto;
  }
}
body #page-home section#about-together {
  text-align: center;
  padding-top: 0;
  background-color: #1A222E;
  background-image: url('../images/together_bg.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
body #page-home section#about-together img {
  display: inline-block;
  transform: scale(0.6);
}
body #page-home section#about-together h1 {
  margin-top: -60px;
}
body #page-home section#about-together .wave-bottom-top {
  background-image: url('../images/wave_bottom_top.png');
  background-position: left top;
  background-repeat: repeat-x;
  padding: 0;
  margin: 0;
  margin-top: -1px;
}
body #page-home section#about-together .wave-bottom-bottom {
  background-image: url('../images/wave_bottom_bottom.png');
  background-position: left top;
  background-repeat: repeat-x;
  padding: 120px 0 30px;
}
body #page-home section#about-together.start .wave-bottom-top {
  animation: bg-scroll-right 20s linear infinite;
}
body #page-home section#about-together.start .wave-bottom-bottom {
  animation: bg-scroll 20s linear infinite;
}
body #page-home section#about-we-are-fans {
  position: relative;
  padding: 0;
}
body #page-home section#about-we-are-fans video {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
body #page-home section#about-we-are-fans h1 {
  width: 612px;
}
@media only screen and (max-width: 620px) {
  body #page-home section#about-we-are-fans h1 {
    width: 100%;
  }
}
body #page-home section#about-our-clients {
  background-color: white;
  padding-bottom: 0;
}
body #page-home section#about-our-clients ul {
  list-style: none outside none;
  width: 100%;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
}
body #page-home section#about-our-clients ul li {
  width: 30%;
  margin-bottom: 20px;
  opacity: 0;
}
body #page-home section#about-our-clients ul li div {
  margin: 0 auto;
}
@media only screen and (min-width: 901px) {
  body #page-home section#about-our-clients ul {
    max-width: 1000px;
    padding: 40px 0 0;
  }
  body #page-home section#about-our-clients ul li {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  body #page-home section#about-our-clients ul li {
    width: 20%;
  }
}
body #page-home section#about-our-approach {
  padding-top: 100px;
  background-color: white;
}
body #page-home section#about-our-approach h1 {
  color: #01AA9F;
}
body #page-home section#about-our-approach .our-approach-image {
  display: block;
  float: right;
  height: 500px;
  margin-top: -100px;
}
body #page-home section#about-our-approach #speak-with-them {
  padding: 50px 30px 80px;
}
@media only screen and (max-width: 900px) {
  body #page-home section#about-our-approach #speak-with-them {
    padding: 50px 30px 50px;
  }
}
@media only screen and (max-width: 1100px) {
  body #page-home section#about-our-approach .our-approach-image {
    float: none;
    margin: 30px auto 0;
  }
  body #page-home section#about-our-approach p {
    text-align: center;
  }
}
body #page-home section#about-contact {
  background-color: white;
  margin-top: -1px;
}
body #page-home section#about-contact #love-bubble {
  display: inline-block;
  height: 80px;
  width: auto;
  margin: 0 10px;
  vertical-align: middle;
  overflow: visible;
}
body #page-home section#about-contact #love-bubble svg {
  height: 100%;
  width: auto;
  overflow: visible;
}
body #page-home section#about-recent-works {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}
body #page-home section#about-recent-works h3 {
  padding: 30px 0;
  background-color: white;
  margin: 0 auto;
}
body #page-home section#about-recent-works .grid-container {
  margin-bottom: 0;
}
body #page-home section#about-recent-works .grid-container .grid-item::after {
  height: 8px;
}
@media only screen and (min-width: 1800px) {
  body #page-home section#about-recent-works .grid-container .grid-item,
  body #page-home section#about-recent-works .grid-container .grid-sizer {
    width: 16.66%;
  }
  body #page-home section#about-recent-works .grid-container .grid-item.double_size,
  body #page-home section#about-recent-works .grid-container .grid-sizer.double_size {
    width: 33.33%;
  }
}
body #page-home section#about-recent-works .btn-1 {
  display: inline-block;
  margin: 30px auto;
}
@media only screen and (max-width: 900px) {
  body #page-home section#about-recent-works .btn-1 {
    width: 320px;
    height: 70px;
    font-size: 28px;
    line-height: 70px;
  }
  body #page-home section#about-recent-works .btn-1.round::before {
    border-radius: 40px;
  }
  body #page-home section#about-recent-works .btn-1.round::after {
    border-radius: 40px;
  }
}
body #page-home section#social {
  height: 400px;
  position: relative;
  background-color: #1A222E;
  padding: 0;
  min-height: 200px;
  overflow: hidden;
}
body #page-home section#social h1 {
  color: white;
}
body #page-home section#social .button-wrapper {
  display: block;
  position: absolute;
  bottom: 0;
  height: 400px;
  background-image: url(../images/blue_fade_cover.png);
  background-position: center bottom;
  background-repeat: repeat-x;
  z-index: 10;
}
body #page-home section#social .button-wrapper .button-padding {
  position: absolute;
  bottom: 0;
}
body #page-home section#home-contact {
  padding: 0 0 80px;
  overflow: hidden;
}
body #page-home section#home-contact h1 {
  font-size: 40px;
  margin-bottom: 50px;
}
body #page-home section#home-contact #ai-logo-contact {
  opacity: 0;
  width: 100%;
  height: 550px;
  padding-top: 50px;
}
body #page-home section#home-contact #ai-logo-contact svg {
  height: 100%;
  width: auto;
}
body #page-home section#home-contact #ai-logo-contact svg path {
  fill: white;
}
body #page-home section#home-contact .contact-info-row.same-width {
  width: 426px;
}
body #page-home section#home-contact #paper-plane-bg {
  display: none;
  position: absolute;
  top: 0px;
  left: 50%;
  width: 960px;
  transform: translateX(-70%);
  z-index: 0;
  pointer-events: none;
}
body #page-home section#home-contact #paper-plane-bg svg {
  overflow: visible;
  width: 100%;
}
@media only screen and (max-width: 1166px) {
  body #page-home section#home-contact #paper-plane-bg {
    top: 170px;
    width: 600px;
    transform: translateX(-97%);
  }
  body #page-home section#home-contact .contact-info-row {
    margin: 10px auto;
  }
  body #page-home section#home-contact .contact-info-row.same-width {
    width: 95%;
  }
}
@keyframes bg-scroll {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -340px 0;
  }
}
@keyframes bg-scroll-right {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 340px 0;
  }
}
body #page-work {
  background-color: #1A222E;
  padding-top: 144px;
  width: 100%;
  height: 100%;
}
body #page-work .loader {
  position: fixed;
}
body #page-work .grid-container {
  margin: 0 auto;
}
body #page-work #filter {
  position: fixed;
  top: 80px;
  left: 0;
  height: 64px;
  font-family: montserrat, sans-serif;
  font-weight: 400;
  font-size: 19px;
  z-index: 90;
  width: 100%;
  background-color: rgba(26, 34, 46, 0.8);
}
body #page-work #filter ul {
  padding-top: 17px;
  text-align: center;
}
body #page-work #filter ul li {
  display: inline-block;
  color: white;
  margin: 0 2px;
  padding: 4px 0;
}
body #page-social {
  background-color: #1A222E;
  padding-top: 80px;
}
body #page-social article {
  cursor: pointer;
}
body #page-social .grid-container {
  margin: 0 auto;
}
#page-job {
  background-color: #1A222E;
  padding-top: 144px;
  width: 100%;
  height: 100%;
  color: #F2EFEF;
}
#page-job h2 {
  margin: 0 auto 2em;
  text-align: center;
}
#page-job .above-title {
  margin: 0;
  color: #01AA9F;
}
#page-job h3 {
  margin: 0 auto 3em;
  font-size: 1em;
}
@media only screen and (max-width: 900px) {
  #page-job h3 {
    margin-bottom: 2em;
    font-size: 30px;
  }
}
#page-job h4 {
  margin: 2em auto 0;
  font-size: 1em;
}
@media only screen and (max-width: 900px) {
  #page-job h4 {
    font-size: 30px;
  }
}
#page-job p {
  margin: 0 auto 1em;
  line-height: 1.5;
}
#page-job article {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}
@media only screen and (min-width: 901px) {
  #page-job article {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
#page-job ul {
  margin: 0 auto 1em;
  line-height: 1.5;
  list-style: disc;
  padding-left: 20px;
}
@media only screen and (max-width: 900px) {
  #page-job ul {
    margin-bottom: 2em;
    font-size: 30px;
  }
}
#page-covid19,
#page-services {
  padding-top: 144px;
  width: 100%;
  height: 100%;
  color: #535353;
}
#page-covid19 h2,
#page-services h2 {
  margin: 0 auto 2em;
  text-align: center;
}
#page-covid19 h3,
#page-services h3 {
  margin: 0 auto;
  font-size: 2em;
  text-align: center;
}
@media only screen and (max-width: 900px) {
  #page-covid19 h3,
  #page-services h3 {
    margin-bottom: 2em;
    font-size: 30px;
  }
}
#page-covid19 h4,
#page-services h4 {
  margin: 3em auto 1em;
  font-size: 1.3em;
}
@media only screen and (max-width: 900px) {
  #page-covid19 h4,
  #page-services h4 {
    font-size: 30px;
  }
}
#page-covid19 h5,
#page-services h5 {
  font-size: 1.1em;
  margin: 2em auto 0;
  color: #01AA9F;
}
#page-covid19 p,
#page-services p {
  margin: 0 auto 1em;
  line-height: 1.5;
}
#page-covid19 p.sub-title,
#page-services p.sub-title {
  text-align: center;
  font-style: italic;
  margin-bottom: 2em;
}
#page-covid19 section,
#page-services section {
  margin-bottom: 4em;
}
#page-covid19 article,
#page-services article {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}
@media only screen and (min-width: 901px) {
  #page-covid19 article,
  #page-services article {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
#page-covid19 ul,
#page-services ul {
  margin: 0 auto 1em;
  line-height: 1.5;
  list-style: disc;
  padding-left: 20px;
}
@media only screen and (max-width: 900px) {
  #page-covid19 ul,
  #page-services ul {
    margin-bottom: 2em;
    font-size: 30px;
  }
}
#page-services h5 {
  margin: 1.25em auto 0.25em;
}
.contact {
  position: relative;
  width: 100%;
  padding: 50px 0;
  background-color: #1A222E;
  border: none;
}
.contact.compact {
  height: 100px;
}
.contact .contact-row {
  margin: 50px auto !important;
}
.contact .contact-info {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  vertical-align: top;
}
.contact .contact-info .contact-info-title {
  display: block;
  color: #01AA9F;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: left;
}
.contact .contact-info .contact-info-title.opps {
  margin-top: -30px;
  margin-bottom: 10px;
}
.contact .contact-info .contact-info-row {
  display: block;
  text-align: left;
}
.contact .contact-info .contact-info-row .contact-info-icon {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
}
.contact .contact-info .contact-info-row .contact-info-icon svg {
  width: 100%;
}
.contact .contact-info .contact-info-row .contact-info-content {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  line-height: 1.4em;
  color: white;
  padding-left: 20px;
  text-align: left;
}
.contact .contact-info .contact-info-row .contact-info-content span {
  display: block;
}
.contact .contact-info .contact-info-row .contact-info-content a,
.contact .contact-info .contact-info-row .contact-info-content span,
.contact .contact-info .contact-info-row .contact-info-content p {
  color: white;
  font-size: 22px;
}
.contact .contact-info .contact-info-row .contact-info-content.higher {
  height: 91px;
}
.contact .contact-info .social-linkouts li {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 10px;
}
.contact .contact-info .social-linkouts li:first-child {
  margin-left: 0;
}
.contact .contact-info .social-linkouts li:last-child {
  margin-right: 0;
}
.contact .contact-info .social-linkouts li a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1166px) {
  .contact .contact-info {
    width: 100%;
    position: relative;
  }
  .contact .contact-info .contact-info-title {
    text-align: center;
    font-size: 26px;
  }
  .contact .contact-info .contact-info-row {
    text-align: center;
  }
  .contact .contact-info .contact-info-row .contact-info-icon {
    width: 60px;
  }
  .contact .contact-info .contact-info-row .contact-info-content {
    font-size: 30px;
    width: 75%;
  }
  .contact .contact-info .contact-info-row .contact-info-content a,
  .contact .contact-info .contact-info-row .contact-info-content span,
  .contact .contact-info .contact-info-row .contact-info-content p {
    font-size: 30px;
  }
  .contact .contact-info .contact-info-row .contact-info-content.higher {
    height: auto;
  }
  .contact .contact-info .contact-info-row .contact-info-content.mobile-higher {
    height: 150px;
  }
  .contact .contact-info .social-linkouts li {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }
  .contact .contact-info .social-linkouts li:first-child {
    margin-left: 0;
  }
  .contact .contact-info .social-linkouts li:last-child {
    margin-right: 0;
  }
  .contact .contact-info .social-linkouts li a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.contact .copyright {
  font-size: 12px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  color: #01AA9F;
  transform: translateX(-50%);
}
.overlay {
  display: none;
  width: 100%;
  z-index: 100;
  background: black;
  opacity: 0;
  overflow: auto;
}
.overlay .padding-container {
  width: 100%;
  height: 100%;
  padding-top: 80px;
}
#mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  overflow: hidden;
  background-color: #F2EFEF;
}
#mobile-nav ul {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 75%;
}
#mobile-nav ul li {
  position: relative;
  text-align: center;
  color: #535353;
  cursor: pointer;
  font-size: 40px;
  font-weight: 700;
  padding: 40px 40px;
}
#mobile-nav ul li.bottom-divider {
  border-bottom: 2px solid #01AA9F;
}
#mobile-nav .mobile-social-linkouts {
  width: 100%;
  height: 80px;
}
#mobile-nav .mobile-social-linkouts ul {
  width: 100%;
  height: 80px;
}
#mobile-nav .mobile-social-linkouts ul li {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 10px 10px;
}
#mobile-nav .mobile-social-linkouts ul li:first-child {
  margin-right: 0;
}
#mobile-nav .mobile-social-linkouts ul li:last-child {
  margin-left: 0;
}
#mobile-nav .mobile-social-linkouts ul li a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#work-detail {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  font-family: montserrat, sans-serif;
  font-weight: 400;
  padding-top: 80px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #F2EFEF;
}
#work-detail em {
  font-weight: 700;
  color: #535353;
}
#work-detail i {
  font-weight: 700;
  color: #535353;
}
#work-detail strong {
  font-weight: 700;
  color: #535353;
}
#work-detail h4 {
  font-weight: 700;
  padding-bottom: 30px;
  font-size: 24px;
  letter-spacing: 2px;
  color: #01AA9F;
  text-transform: uppercase;
  line-height: 1.2em;
}
@media only screen and (max-width: 900px) {
  #work-detail h4 {
    font-size: 36px;
  }
}
#work-detail p a {
  color: #01AA9F;
  font-weight: 700;
}
#work-detail svg .ai-svg-wrapper path {
  fill: #535353;
}
#work-detail svg .ai-svg-wrapper circle {
  fill: #535353;
}
#work-detail .ai-svg-hover {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
}
#work-detail .ai-svg-hover:hover {
  background-color: #01AA9F;
}
#work-detail .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
#work-detail .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle {
  fill: white;
}
#work-detail .ai-svg-hover:hover .ai-social-icn-ring,
#work-detail .ai-svg-hover:hover .ai-arrow-ring {
  fill: #01AA9F;
}
#work-detail .column-center {
  width: 1200px;
  height: auto;
  margin: 0 auto;
}
#work-detail .vimeo-player,
#work-detail .video-player {
  position: relative;
  margin: 0 auto;
}
#work-detail .vimeo-player img,
#work-detail .video-player img {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
#work-detail .vimeo-player .play-button,
#work-detail .video-player .play-button {
  position: absolute;
  width: 20%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
#work-detail .vimeo-player .play-button.light svg .ai-svg-wrapper path,
#work-detail .video-player .play-button.light svg .ai-svg-wrapper path {
  fill: #F2EFEF;
}
#work-detail .vimeo-player .play-button.light svg .ai-svg-wrapper circle,
#work-detail .video-player .play-button.light svg .ai-svg-wrapper circle {
  fill: #F2EFEF;
}
#work-detail .vimeo-player .play-button.light .ai-svg-hover,
#work-detail .video-player .play-button.light .ai-svg-hover {
  font-size: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
}
#work-detail .vimeo-player .play-button.light .ai-svg-hover:hover,
#work-detail .video-player .play-button.light .ai-svg-hover:hover {
  background-color: #F2EFEF;
}
#work-detail .vimeo-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
#work-detail .video-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
#work-detail .vimeo-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle,
#work-detail .video-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle {
  fill: white;
}
#work-detail .vimeo-player .close-button,
#work-detail .video-player .close-button {
  width: 10%;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  opacity: 0;
  cursor: pointer;
}
#work-detail .vimeo-player .close-button.light svg .ai-svg-wrapper path,
#work-detail .video-player .close-button.light svg .ai-svg-wrapper path {
  fill: #F2EFEF;
}
#work-detail .vimeo-player .close-button.light svg .ai-svg-wrapper circle,
#work-detail .video-player .close-button.light svg .ai-svg-wrapper circle {
  fill: #F2EFEF;
}
#work-detail .vimeo-player .close-button.active,
#work-detail .video-player .close-button.active {
  opacity: 1;
}
#work-detail .vimeo-player .vimeo-iframe-wrapper,
#work-detail .video-player .vimeo-iframe-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#work-detail .vimeo-player video,
#work-detail .video-player video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#work-detail .vimeo-player.active .play-button,
#work-detail .video-player.active .play-button {
  display: none;
}
#work-detail .image-gallery {
  position: relative;
  width: 100%;
  height: 700px;
  margin: 0;
}
#work-detail .image-gallery .media {
  position: relative;
  height: 100%;
  overflow: hidden;
}
#work-detail .image-gallery .media .media-item {
  display: none;
  position: absolute;
  margin: 0;
  width: 100%;
  height: 100%;
}
#work-detail .image-gallery .media .media-item img {
  display: block;
  height: 100%;
  margin: 0 auto;
}
#work-detail .image-gallery .media .media-item .iphone-wrapper {
  overflow: hidden;
}
#work-detail .image-gallery .media .button-area {
  position: absolute;
  width: 100%;
  height: 90%;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  z-index: 2;
}
#work-detail .image-gallery .media .button-area .button-flex {
  position: relative;
  width: 10%;
  height: auto;
}
#work-detail .image-gallery .media .button-area .play {
  position: absolute;
  width: 100%;
  height: auto;
}
#work-detail .image-gallery .media .button-area .pause {
  position: absolute;
  display: none;
  width: 100%;
  height: auto;
}
#work-detail .image-gallery .media .gallery-video video {
  width: 100%;
  height: 100%;
}
#work-detail .work-detail-titles {
  width: 100%;
  text-align: center;
}
#work-detail .work-detail-titles .project-title {
  line-height: 40px;
  font-size: 40px;
  color: #535353;
  margin: 30px 0;
  text-transform: uppercase;
}
#work-detail .image-gallery-controls {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
}
#work-detail .image-gallery-controls li {
  list-style-type: none;
}
#work-detail .image-gallery-controls .control-btn {
  display: inline-block;
  width: 30px;
  overflow: hidden;
  font-size: 90px;
  height: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #C9C9C9;
}
#work-detail .image-gallery-controls .control-btn.active {
  color: #535353;
}
#work-detail .image-gallery-controls .control-btn:hover {
  color: #535353;
}
#work-detail .work-detail-info {
  width: 100%;
  margin: 0;
  overflow-y: visible;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.6em;
  font-family: montserrat, sans-serif;
  font-weight: 400;
}
#work-detail .work-detail-info .info-container {
  clear: both;
  text-align: center;
  padding: 0 0 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#work-detail .work-detail-info .info-container:after {
  content: '';
  display: block;
  clear: both;
}
#work-detail .work-detail-info .info-container p {
  padding-bottom: 20px;
  text-align: left;
}
#work-detail .work-detail-info .info-container .project-subtitle {
  width: 70%;
  float: left;
  text-align: left;
}
#work-detail .work-detail-info .info-container .project-description {
  width: 70%;
  float: left;
}
#work-detail .work-detail-info .info-container .client-icon {
  width: 30%;
  float: right;
  text-align: center;
  margin-bottom: 30px;
  transform: scale(0.8);
}
#work-detail .work-detail-info .info-container .client-icon img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
#work-detail .work-detail-info .info-container .project-links,
#work-detail .work-detail-info .info-container .project-tags {
  text-align: left;
}
#work-detail .work-detail-info .info-container .project-tags {
  font-size: 15px;
  padding-bottom: 30px;
}
#work-detail .work-detail-info .info-container .project-tags .tag {
  color: #535353;
}
#work-detail .work-detail-info .info-container .project-links a,
#work-detail .work-detail-info .info-container .project-links span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-weight: 700;
  color: #535353;
}
#work-detail .work-detail-info .info-container .project-links a:hover {
  color: #01AA9F;
}
#work-detail .work-detail-info .info-container .project-links .facebook,
#work-detail .work-detail-info .info-container .project-links .twitter,
#work-detail .work-detail-info .info-container .project-links .instagram,
#work-detail .work-detail-info .info-container .project-links .giphy,
#work-detail .work-detail-info .info-container .project-links .youtube {
  width: 30px;
  height: 30px;
}
#work-detail .work-detail-info .info-container .project-links .project-link-separator {
  height: 30px;
  width: 2px;
  background-color: #535353;
}
#work-detail .grid-container {
  margin: 0 auto;
}
#work-detail .work-detail-related {
  background-color: #1A222E;
  text-align: center;
}
#work-detail .work-detail-related .end-of-article {
  position: relative;
  width: 100%;
  height: 140px;
  background-color: #F2EFEF;
  margin-bottom: 30px;
}
#work-detail .work-detail-related .end-of-article .hr {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 6px;
  background-color: #01AA9F;
}
#work-detail .work-detail-related .end-of-article .logo {
  position: absolute;
  height: 70px;
  width: 70px;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
  border-radius: 50%;
}
#work-detail .work-detail-related .end-of-article .logo path {
  fill: #01AA9F;
}
#work-detail .work-detail-related h4 {
  width: 100%;
  text-align: center;
  color: #01AA9F !important;
}
#work-detail .work-detail-related .btn-1 {
  margin: 30px auto;
}
#work-detail.case-study .work-detail-container .cs-module {
  position: relative;
  margin-bottom: 50px;
}
#work-detail.case-study .work-detail-container .cs-module h4,
#work-detail.case-study .work-detail-container .cs-module p {
  text-align: center;
  padding-bottom: 30px;
}
#work-detail.case-study .work-detail-container .column-center {
  width: 1000px;
}
#work-detail.case-study .work-detail-container .work-detail-info .project-tags {
  display: none;
  padding-bottom: 0;
}
#work-detail.case-study .work-detail-container .image-gallery {
  height: auto;
  background-color: black;
  margin-bottom: 30px;
  overflow: hidden;
}
#work-detail.case-study .work-detail-container .image-gallery .media {
  text-align: center;
  overflow: visible;
}
#work-detail.case-study .work-detail-container .image-gallery .media.column-center {
  width: auto;
}
#work-detail.case-study .work-detail-container .image-gallery .media img {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
#work-detail.case-study .work-detail-container .image-gallery .work-detail-titles {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
#work-detail.case-study .work-detail-container .image-gallery .work-detail-titles .project-title {
  color: white;
}
#work-detail.case-study .work-detail-container .image-gallery-controls {
  display: none;
}
#work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper .social-grid-item {
  display: block;
  width: 32%;
  font-size: 0;
  margin: 0 0.5% 1% 0.5%;
}
#work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper .social-grid-item img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}
#work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper .social-grid-item:last-child {
  align-self: center;
}
#work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper .social-grid-item.width-full {
  width: 98%;
}
@media only screen and (max-width: 900px) {
  #work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper .social-grid-item.width-full {
    width: 100%;
  }
}
#work-detail.case-study .work-detail-container .cs-banner {
  width: 100%;
  background-color: black;
  height: 340px;
  text-align: center;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media {
  position: relative;
  width: 384px;
  height: 795px;
  background-image: url('../images/mobile_case.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .phone-media-wrapper {
  position: absolute;
  width: 340px;
  height: 600px;
  top: 98px;
  left: 23px;
  overflow: hidden;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .phone-media-wrapper .phone-media-item {
  display: none;
  position: absolute;
  width: 340px;
  height: 600px;
  top: 0;
  left: 0;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .phone-media-wrapper .phone-media-item > img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .phone-over {
  display: none;
  pointer-events: none;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .carousel-arrow {
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  opacity: 1;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .carousel-arrow.left {
  left: -100px;
}
#work-detail.case-study .work-detail-container .phone-gallery .phone-media .carousel-arrow.right {
  right: -100px;
}
#work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media {
  height: 768px;
  background: transparent;
}
#work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media .phone-media-wrapper {
  position: absolute;
  width: 334px;
  height: 722px;
  top: 23px;
  left: 25px;
}
#work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media .phone-media-wrapper .phone-media-item {
  width: 334px;
  height: 722px;
}
#work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media .phone-over {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url('../images/mobile_case_x.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
#work-detail.case-study .work-detail-container .phone-gallery.no-frame-16-9 .phone-media {
  width: 60%;
  height: 0;
  padding-bottom: 33.75%;
  background: transparent;
}
#work-detail.case-study .work-detail-container .phone-gallery.no-frame-16-9 .phone-media .phone-media-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#work-detail.case-study .work-detail-container .phone-gallery.no-frame-16-9 .phone-media .phone-media-wrapper .phone-media-item {
  width: 100%;
  height: 100%;
}
#work-detail .contact {
  margin-top: -1px;
}
#work-detail .contact .contact-info .contact-address h4 {
  color: white;
}
@media only screen and (max-width: 900px) {
  #work-detail .work-detail-info .info-container .project-links .facebook,
  #work-detail .work-detail-info .info-container .project-links .twitter,
  #work-detail .work-detail-info .info-container .project-links .instagram,
  #work-detail .work-detail-info .info-container .project-links .giphy,
  #work-detail .work-detail-info .info-container .project-links .youtube {
    width: 60px;
    height: 60px;
  }
  #work-detail .work-detail-info .info-container .project-links .project-link-separator {
    height: 60px;
    width: 2px;
    background-color: #535353;
  }
  #work-detail .work-detail-info .info-container .project-tags {
    font-size: 24px;
  }
  #work-detail .work-detail-related .btn-1 {
    width: 320px;
    height: 70px;
    font-size: 28px;
    line-height: 70px;
  }
  #work-detail .work-detail-related .btn-1.round::before {
    border-radius: 40px;
  }
  #work-detail .work-detail-related .btn-1.round::after {
    border-radius: 40px;
  }
  #work-detail.case-study .work-detail-container .social-grid .social-grid-wrapper .social-grid-item {
    width: 49%;
    margin-bottom: 2%;
  }
}
@media only screen and (max-width: 768px) {
  #work-detail .vimeo-player.main-video,
  #work-detail .video-player.main-video {
    width: 100% !important;
  }
  #work-detail .image-gallery .carousel-arrow {
    top: auto;
    bottom: -72px;
    margin-top: 0;
  }
  #work-detail .image-gallery .carousel-arrow.left {
    left: 10px;
  }
  #work-detail .image-gallery .carousel-arrow.right {
    right: 10px;
  }
  #work-detail .work-detail-info .info-container .project-subtitle {
    width: 100%;
    float: none;
    text-align: center;
  }
  #work-detail .work-detail-info .info-container .project-links,
  #work-detail .work-detail-info .info-container .project-tags {
    text-align: center;
  }
  #work-detail .work-detail-info .info-container .project-description {
    width: 100%;
    float: none;
  }
  #work-detail .work-detail-info .info-container .client-icon {
    width: 100%;
    float: none;
    text-align: center;
  }
  #work-detail.case-study .work-detail-container .phone-gallery .phone-media {
    width: 350px;
    height: 727px;
  }
  #work-detail.case-study .work-detail-container .phone-gallery .phone-media .carousel-arrow {
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    opacity: 1;
  }
  #work-detail.case-study .work-detail-container .phone-gallery .phone-media .carousel-arrow.left {
    left: -90px;
  }
  #work-detail.case-study .work-detail-container .phone-gallery .phone-media .carousel-arrow.right {
    right: -90px;
  }
  #work-detail.case-study .work-detail-container .phone-gallery .phone-media .phone-media-wrapper {
    width: 314px;
    height: 552px;
    top: 87px;
    left: 18px;
  }
  #work-detail.case-study .work-detail-container .phone-gallery .phone-media .phone-media-wrapper .phone-media-item {
    width: 100%;
    height: 100%;
  }
  #work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media {
    height: 698px;
  }
  #work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media .phone-media-wrapper {
    width: 304px;
    height: 658px;
    top: 20px;
    left: 23px;
  }
  #work-detail.case-study .work-detail-container .phone-gallery.iphone-x .phone-media .phone-media-wrapper .phone-media-item {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 640px) {
  #work-detail .image-gallery .carousel_arrow {
    top: auto;
    bottom: -72px;
    margin-top: 0;
  }
  #work-detail .image-gallery .carousel_arrow.left {
    left: 10px;
  }
  #work-detail .image-gallery .carousel_arrow.right {
    right: 10px;
  }
}
body.mobile #work-detail .vimeo-player .play-button.light .ai-svg-hover:hover,
body.mobile #work-detail .video-player .play-button.light .ai-svg-hover:hover {
  background-color: transparent;
}
body.mobile #work-detail .vimeo-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
body.mobile #work-detail .video-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
body.mobile #work-detail .vimeo-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle,
body.mobile #work-detail .video-player .play-button.light .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle {
  fill: #535353;
}
body.mobile #work-detail .image-gallery-controls .control-btn:hover {
  color: #C9C9C9;
}
body.mobile #work-detail .work-detail-info .info-container .project-links .project-link:hover {
  color: #535353;
}
body.mobile #work-detail.case-study .ai-svg-hover:hover {
  background-color: transparent;
}
body.mobile #work-detail.case-study .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
body.mobile #work-detail.case-study .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle {
  fill: #535353;
}
.asset-html-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.asset-html-container .asset-html-column {
  float: left;
  height: 100%;
  padding: 0;
  margin: 0;
}
.asset-html-container .asset-html-column .asset-html-item {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding: 0;
  margin: 0;
}
.asset-html-container .asset-html-column .asset-html-item.float-left {
  float: left;
}
.asset-html-container .asset-html-column .asset-html-item a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}
.asset-html-container .width-16 {
  width: 16% !important;
}
.asset-html-container .width-20 {
  width: 20% !important;
}
.asset-html-container .width-25 {
  width: 25% !important;
}
.asset-html-container .width-28 {
  width: 28% !important;
}
.asset-html-container .width-30 {
  width: 30% !important;
}
.asset-html-container .width-35 {
  width: 35% !important;
}
.asset-html-container .width-40 {
  width: 40% !important;
}
.asset-html-container .width-50 {
  width: 50% !important;
}
.asset-html-container .width-60 {
  width: 60% !important;
}
.asset-html-container .width-75 {
  width: 75% !important;
}
.asset-html-container .width-full {
  width: 100% !important;
}
.asset-html-container .height-25 {
  height: 25%;
}
.asset-html-container .height-30 {
  height: 30%;
}
.asset-html-container .height-33 {
  height: 33%;
}
.asset-html-container .height-34 {
  height: 34%;
}
.asset-html-container .height-35 {
  height: 35%;
}
.asset-html-container .height-40 {
  height: 40%;
}
.asset-html-container .height-45 {
  height: 45%;
}
.asset-html-container .height-50 {
  height: 50%;
}
.asset-html-container .height-55 {
  height: 55%;
}
.asset-html-container .height-60 {
  height: 60%;
}
.asset-html-container .height-65 {
  height: 65%;
}
.asset-html-container .height-67 {
  height: 67%;
}
.asset-html-container .height-70 {
  height: 70%;
}
.asset-html-container .height-full {
  height: 100%;
}
.asset-html-container .asset-img {
  min-width: 100%;
  min-height: 100%;
}
.asset-html-container .asset-html-column .asset-html-item.bg-white {
  background-color: white;
}
.asset-html-container .asset-html-column .asset-html-item img {
  width: 100%;
  position: absolute;
  left: -9999px;
  right: -9999px;
  margin: auto;
}
.asset-html-container .asset-html-column .asset-html-item .full-height {
  width: auto !important;
  height: 100% !important;
}
body.mobile button.btn-1:hover span {
  color: #01AA9F;
}
body.mobile button.btn-1:hover span a {
  color: #01AA9F !important;
}
body.mobile button.btn-1:hover::before {
  opacity: 0 !important;
  transform: scale(1, 0.1) !important;
}
body.mobile button.btn-1:hover::after {
  transform: scale(1, 1) !important;
  opacity: 1 !important;
}
body.mobile .ai-svg-hover:hover {
  background-color: transparent;
}
body.mobile .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group path,
body.mobile .ai-svg-hover:hover svg .ai-svg-wrapper .ai-svg-icn-group circle {
  fill: #01AA9F;
}
body.mobile .ai-hover:hover::after {
  width: 0;
}
body.mobile .grid-container .grid-item:hover::after {
  width: 0;
}
body.mobile .img-hover:hover {
  opacity: 1;
}
body.mobile section#marquee #marquee-container .marquee-controls .marquee-control-button:hover {
  color: #C9C9C9;
}
