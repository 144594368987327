@import "variables";

#work-detail {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    font-family: @font-family-ms;
    font-weight: 400;
    padding-top: 80px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: @ai-ivory;
    em {
        font-weight: 700;
        color: @ai-dark-grey;
    }
    i {
        font-weight: 700;
        color: @ai-dark-grey;
    }
    strong {
        font-weight: 700;
        color: @ai-dark-grey;
    }
    h4 {
        font-weight: 700;
        padding-bottom: 30px;
        font-size: 24px;
        letter-spacing: 2px;
        color: @ai-teal;
        text-transform: uppercase;
        line-height: 1.2em;
        @media only screen and (max-width: @screen-max) {
            font-size: 36px;
        }
    }
    p {
        a {
            color: @ai-teal;
            font-weight: 700;
        }
    }
    svg {
        .ai-svg-wrapper {
            path {
                fill: @ai-dark-grey;
            }
            circle {
                fill: @ai-dark-grey;
            }
        }
    }
    .ai-svg-hover {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0);
        &:hover {
            background-color: @ai-teal;
            svg {
                .ai-svg-wrapper {
                    .ai-svg-icn-group {
                        path, circle {
                            fill: white;
                        }
                    }
                }
            }
            .ai-social-icn-ring, .ai-arrow-ring {
                fill: @ai-teal;
            }
        }
    }
    .column-center {
        width: 1200px;
        height: auto;
        margin: 0 auto;
    }
    .vimeo-player, .video-player {
        position: relative;
        margin: 0 auto;
        img {
            display: inline-block;
            width: 100%;
            cursor: pointer;
        }
        .play-button {
            position: absolute;
            width: 20%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            &.light {
                svg {
                    .ai-svg-wrapper {
                        path {
                            fill: @ai-ivory;
                        }
                        circle {
                            fill: @ai-ivory;
                        }
                    }
                }
                .ai-svg-hover {
                    font-size: 0;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0);
                    &:hover {
                        background-color: @ai-ivory;
                        svg {
                            .ai-svg-wrapper {
                                .ai-svg-icn-group {
                                    path, circle {
                                        fill: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .close-button {
			width: 10%;
			text-align: center;
			position: absolute;
			top: 20px;
			left: 50%;
            transform: translateX(-50%);
            transition: opacity 0.3s ease;
            opacity: 0;
            cursor: pointer;
            &.light {
                svg {
                    .ai-svg-wrapper {
                        path {
                            fill: @ai-ivory;
                        }
                        circle {
                            fill: @ai-ivory;
                        }
                    }
                }
            }
            &.active {
                opacity: 1;
            }
        }
        .vimeo-iframe-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        &.active {
            .play-button {
                display: none;
            }
        }
    }
    .image-gallery {
        position: relative;
        width: 100%;
        height: 700px;
        margin: 0;
        .media {
            position: relative;
            height: 100%;
            overflow: hidden;
            .media-item {
                display: none;
                position: absolute;
                margin: 0;
                width: 100%;
                height: 100%;
                img {
                    display: block;
                    height: 100%;
                    margin: 0 auto;
                }
                .iphone-wrapper {
                    overflow: hidden;
                }
            }
            .button-area {
                position: absolute;
                width: 100%;
                height: 90%;
                top: 0;
                left: 0;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                z-index: 2;
                .button-flex {
                    position: relative;
                    width: 10%;
                    height: auto;
                }
                .play {
                    position: absolute;
                    width: 100%;
                    height: auto;
                }
                .pause {
                    position: absolute;
                    display: none;
                    width: 100%;
                    height: auto;
                }
            }
            .gallery-video video {
                width: 100%;
                height: 100%;
            }
        }
    }
    .work-detail-titles {
        width: 100%;
        text-align: center;
        .project-title {
            line-height: 40px;
            font-size: 40px;
            color: @ai-dark-grey;
            margin: 30px 0;
            text-transform: uppercase;
        }
    }
    .image-gallery-controls {
        width: 100%;
        height: 40px;
        margin: 0;
        padding: 0;
        text-align: center;
        li {
            list-style-type: none;
        }
        .control-btn {
            display: inline-block;
            width: 30px;
            overflow:hidden;
            font-size: 90px;
            height: 40px;
            line-height: 50px;
            cursor: pointer;
            color: @ai-light-grey;
            &.active {
                color: @ai-dark-grey;
            }
            &:hover {
                color: @ai-dark-grey;
            }
        }
    }
    .work-detail-info {
        width: 100%;
        margin:0;
        overflow-y: visible;
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 1.6em;
        font-family: @font-family-ms;
        font-weight: 400;
        .info-container {
            clear: both;
            text-align: center;
            padding: 0 0 30px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &:after {
                content: '';
                display: block;
                clear: both;
            }
            p {
                padding-bottom: 20px;
                text-align: left;
            }
            .project-subtitle {
                width: 70%;
                float: left;
                text-align: left;
            }
            .project-description {
                width: 70%;
                float: left;
            }
            .client-icon {
                width: 30%;
                float: right;
                text-align: center;
                margin-bottom: 30px;
                transform: scale(0.8);
                img {
                    display: inline-block;
                    max-width: 100%;
                    height: auto;
                }
            }
            .project-links, .project-tags {
                text-align: left;
            }
            .project-tags {
                font-size: 15px;
                padding-bottom: 30px;
                .tag {
                    color: @ai-dark-grey;
                }
            }
            .project-links {
                a, span {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                    font-weight: 700;
                    color: @ai-dark-grey;
                }
                a:hover {
                    color: @ai-teal;
                }
                .facebook, .twitter, .instagram, .giphy, .youtube {
                    width: 30px;
                    height: 30px;
                }
                .project-link-separator {
                    height: 30px;
                    width: 2px;
                    background-color: @ai-dark-grey;
                }
            }
        }
    }
    .grid-container {
        margin: 0 auto;
    }

    .work-detail-related {
        background-color: @ai-dark-blue;
        text-align: center;
        .end-of-article {
            position: relative;
            width: 100%;
            height: 140px;
            background-color: @ai-ivory;
            margin-bottom: 30px;
            .hr {
                position: absolute;
                width: 100%;
                bottom: 0;
                height: 6px;
                background-color: @ai-teal;
            }
            .logo {
                position: absolute;
                //padding: 10px;
                height: 70px;
                width: 70px;
                left: 50%;
                top: 50%;
                margin-left: -35px;
                margin-top: -35px;
                border-radius: 50%;
                path {
                    fill: @ai-teal;
                }
            }
        }
        h4 {
            width: 100%;
            text-align: center;
            color: @ai-teal !important;
        }
        .btn-1 {
            margin: 30px auto;
        }
    }
    &.case-study {
        .work-detail-container {
            .cs-module {
                position: relative;
                margin-bottom: 50px;
                h4, p {
                    text-align: center;
                    padding-bottom: 30px;
                }
            }
            .column-center {
                width: 1000px;
            }
            .work-detail-info {
                .project-tags {
                    display: none;
                    padding-bottom: 0;
                }
            }
            .image-gallery {
                height: auto;
                background-color: black;
                margin-bottom: 30px;
                overflow: hidden;
                .media {
                    text-align: center;
                    overflow: visible;
                    &.column-center {
                        width: auto;
                    }
                    img {
                        position: absolute;
                        height: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                .work-detail-titles {
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    .project-title {
                        color: white;
                    }
                }
            }
            .image-gallery-controls {
                display: none;
            }
            .social-grid {
                .social-grid-wrapper {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .social-grid-item {
                        display: block;
                        width: 32%;
                        font-size: 0;
                        margin: 0 0.5% 1% 0.5%;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            width: 100%;
                            height: auto;
                        }
                        &:last-child {
                            align-self: center;
                        }
                        &.width-full {
                            width: 98%;
                            @media only screen and (max-width: @screen-max) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .cs-banner {
                width: 100%;
                background-color: black;
                height: 340px;
                text-align: center;
            }
            .phone-gallery {
                .phone-media {
                    position: relative;
                    width: 384px;
                    height: 795px;
                    background-image: url('../images/mobile_case.png');
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    margin: 0 auto;
                    .phone-media-wrapper {
                        position: absolute;
                        width: 340px;
                        height: 600px;
                        top: 98px;
                        left: 23px;
                        overflow: hidden;
                        .phone-media-item {
                            display: none;
                            position: absolute;
                            width: 340px;
                            height: 600px;
                            top: 0;
                            left: 0;
                            & > img {
                                position: absolute;
                                width: 100%;
                                height: auto;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                    .phone-over {
                        display: none;
                        pointer-events: none;
                    }
                    .carousel-arrow {
                        top: 50%;
                        transform: translateY(-50%);
                        margin: 0;
                        opacity: 1;
                        &.left {
                            left: -100px;
                        }
                        &.right {
                            right: -100px;
                        }
                    }
                }
                &.iphone-x {
                    .phone-media {
                        height: 768px;
                        background: transparent;
                        .phone-media-wrapper { //0.742746615087041, 35, 31
                            position: absolute;
                            width: 334px;
                            height: 722px;
                            top: 23px;
                            left: 25px;
                            .phone-media-item {
                                width: 334px;
                                height: 722px;
                            }
                        }
                        .phone-over {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-image: url('../images/mobile_case_x.png');
                            background-size: cover;
                            background-position: center center;
                            background-repeat: no-repeat;
                        }
                    }
                }
                &.no-frame-16-9 {
                    .phone-media {
                        width: 60%;
                        height: 0;
                        padding-bottom: 33.75%;
                        background: transparent;
                        .phone-media-wrapper { //0.742746615087041, 35, 31
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            .phone-media-item {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .contact {
        margin-top: -1px;
        .contact-info {
            .contact-address {
                h4 {
                    color: white;
                }
            }
        }
    }
}

@media only screen and (max-width: @screen-max) {
    #work-detail {
        .work-detail-info {
            .info-container {
                .project-links {
                    .facebook, .twitter, .instagram, .giphy, .youtube {
                        width: 60px;
                        height: 60px;
                    }
                    .project-link-separator {
                        height: 60px;
                        width: 2px;
                        background-color: @ai-dark-grey;
                    }
                }
                .project-tags {
                    font-size: 24px;
                }
            }
        }
        .work-detail-related {
            .btn-1 {
                width: 320px;
                height: 70px;
                font-size: 28px;
                line-height: 70px;
                &.round {
                    &::before {
                        border-radius: 40px;
                    }
                    &::after {
                        border-radius: 40px;
                    }
                }
            }
        }
        &.case-study {
            .work-detail-container {
                .cs-banner {
                }
                .social-grid {
                    .social-grid-wrapper {
                        .social-grid-item {
                            width: 49%;
                            margin-bottom: 2%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #work-detail {
        .vimeo-player, .video-player {
            &.main-video {
                width: 100% !important;
            }
        }
        .image-gallery {
            .carousel-arrow {
                top: auto;
                bottom: -72px;
                margin-top: 0;
                &.left {
                    left: 10px;
                }
                &.right {
                    right: 10px;
                }
            }
        }
        .work-detail-info {
            .info-container {
                .project-subtitle {
                    width: 100%;
                    float: none;
                    text-align: center;
                }
                .project-links, .project-tags {
                    text-align: center;
                }
                .project-description {
                    width: 100%;
                    float: none;
                }
                .client-icon {
                    width: 100%;
                    float: none;
                    text-align: center;
                }
            }
        }
        &.case-study {
            .work-detail-container {
                //.phone-gallery {
                //    .phone-media {
                //        .carousel-arrow {
                //            top: 50%;
                //            transform: translateY(-50%);
                //            margin: 0;
                //            opacity: 1;
                //            &.left {
                //                left: -60px;
                //            }
                //            &.right {
                //                right: -60px;
                //            }
                //        }
                //    }
                //}
                .phone-gallery {
                    .phone-media {
                        width: 350px;
                        height: 727px;

                        .carousel-arrow {
                            top: 50%;
                            transform: translateY(-50%);
                            margin: 0;
                            opacity: 1;

                            &.left {
                                left: -90px;
                            }

                            &.right {
                                right: -90px;
                            }
                        }

                        .phone-media-wrapper {
                            width: 314px;
                            height: 552px;
                            top: 87px;
                            left: 18px;

                            .phone-media-item {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    &.iphone-x {
                        .phone-media {
                            height: 698px;
                            .phone-media-wrapper {
                                width: 304px;
                                height: 658px;
                                top: 20px;
                                left: 23px;
                                .phone-media-item {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 640px) {
    #work-detail {
        .image-gallery {
            .carousel_arrow {
                top: auto;
                bottom: -72px;
                margin-top: 0;
                &.left {
                    left: 10px;
                }
                &.right {
                    right: 10px;
                }
            }
        }
    }
}

body {
    &.mobile {
        #work-detail {
            .vimeo-player, .video-player {
                .play-button {
                    &.light {
                        .ai-svg-hover {
                            &:hover {
                                background-color: transparent;
                                svg {
                                    .ai-svg-wrapper {
                                        .ai-svg-icn-group {
                                            path, circle {
                                                fill: @ai-dark-grey;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .image-gallery-controls {
                .control-btn {
                    &:hover {
                        color: @ai-light-grey;
                    }
                }
            }
            .work-detail-info {
                .info-container {
                    .project-links {
                        .project-link {
                            &:hover {
                                color: @ai-dark-grey;
                            }
                        }
                    }
                }
            }
            &.case-study {
                .ai-svg-hover {
                    &:hover {
                        background-color: transparent;
                        svg {
                            .ai-svg-wrapper {
                                .ai-svg-icn-group {
                                    path, circle {
                                        fill: @ai-dark-grey;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
