 .asset-html-container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    .asset-html-column {
        float: left;
        height: 100%;
        padding:0;
        margin:0;
        .asset-html-item {
            position: relative;
            width: 100%;
            overflow: hidden;
            text-align: center;
            padding:0; margin:0;
            &.float-left {
                float: left;
            }
            a {
                text-decoration: none;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .width-16{width: 16% !important;}
    .width-20{width: 20% !important;}
    .width-25{width: 25% !important;}
    .width-28{width: 28% !important;}
    .width-30{width: 30% !important;}
    .width-35{width: 35% !important;}
    .width-40{width: 40% !important;}
    .width-50{width: 50% !important;}
    .width-60{width: 60% !important;}
    .width-75{width: 75% !important;}
    .width-full{width: 100% !important;}
    .height-25{height: 25%;}
    .height-30{height: 30%;}
    .height-33{height: 33%;}
    .height-34{height: 34%;}
    .height-35{height: 35%;}
    .height-40{height: 40%;}
    .height-45{height: 45%;}
    .height-50{height: 50%;}
    .height-55{height: 55%;}
    .height-60{height: 60%;}
    .height-65{height: 65%;}
    .height-67{height: 67%;}
    .height-70{height: 70%;}
    .height-full{height: 100%;}
    .asset-img {min-width: 100%; min-height: 100%;}
    .asset-html-column {
        .asset-html-item {
            &.bg-white{
                background-color: white;
            }
            img {
                width: 100%;
                position: absolute;
                left: -9999px;
                right: -9999px;
                margin: auto;
            }
            .full-height {
                width: auto !important;
                height: 100% !important;
            }
        }
    }
}
