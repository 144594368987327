@import "variables";
@import "includes/sprite-mobile";

*,*::before,*::after {
    box-sizing: border-box;
}

body {
    background-color: @ai-ivory;
    color: @ai-dark-grey;
    font-family: @font-family-ms;
    font-weight: 400;
    &.no-scroll {
        overflow-x: hidden;
        overflow-y: hidden;
    }
    @media only screen and (max-width: @screen-max) {
        &.no-scroll {
            position: fixed;
        }
    }
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        margin: 0;
        padding: 0;
    }
}

h1 {
    font-size: 80px;
    padding: 18px 0;
    margin: 0;
    @media only screen and (max-width: @screen-mini) {
        font-size: 60px;
    }
}

h2, .h2 {
    font-size: 30px;
    @media only screen and (max-width: @screen-max) {
        font-size: 36px;
    }
}

h3 {
    font-size: 26px;
    margin: 30px auto;
    @media only screen and (min-width: @screen-min) {
        font-size: 26px;
    }
    @media only screen and (max-width: @screen-max) {
        font-size: 30px;
    }
}

h4 {
    font-size: 24px;
    @media only screen and (max-width: @screen-max) {
        font-size: 36px;
    }
}

.ai-em {
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    font-style: normal;
}

p {
    line-height: 1.8em;
    margin: 0px;
    font-size: 16px;
    &.home-content {
        font-size: 22px;
    }
    @media only screen and (max-width: @screen-max) {
        font-size: 30px;
        &.home-content {
            font-size: 30px;
        }
    }
}

a {
    text-decoration: none;
    color: @ai-teal;
    &:visited {
        color: @ai-teal;
    }
    &.underline {
        text-decoration: underline;
    }
}

input[type="checkbox"] {
    display: none;
}

.double-letter-space {
    letter-spacing: 8px;
}

.font-weight-bold {
    font-family: @font-family-ms;
    font-weight: 700;
}

.font-weight-medium {
    font-family: @font-family-ms;
    font-weight: 500;
}

.font-weight-light {
    font-family: @font-family-ms;
    font-weight: 400;
}

.align-center {
    text-align: center;
}

.vertical-align-top {
    vertical-align: top !important;
}
.vertical-align-middle {
    vertical-align: middle !important;
}
.vertical-align-bottom {
    vertical-align: bottom !important;
}

.color-ai-teal {
    color: @ai-teal;
}

.color-ai-black {
    color: black;
}

.color-ai-white {
    color: white;
}

button {
    position: relative;
    margin: 0 auto;
    border: none;
    overflow: visible;
    background: transparent;
    -webkit-appearance: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    font-family: @font-family-ms;
    &.btn-1 {
        line-height: 38px;
        transition: all 0.3s;
        width: 160px;
        height: 36px;
        span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            text-align: center;
            color: @ai-teal;
            transition: all 0.3s;
            transform: scale(1, 1);
            z-index: 1;
            a {
                transition: all 0.3s;
                color: @ai-teal !important;
            }
        }
        &::before, &::after {
            content: '';
            position: absolute;
            transition: all 0.3s;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &::before {
            opacity: 0;
            background: @ai-teal;
            transform: scale(1, 0.1);
        }

        &::after {
            transition: all 0.3s;
            border: 2px solid @ai-teal;
        }

        &:hover {
            span {
                color: white;
                a {
                    color: white !important;
                }
            }
            &::before {
                opacity: 1;
                transform: scale(1, 1);
            }
            &::after {
                transform: scale(1, .1);
                opacity: 0;
            }
        }

        &.round {
            &::before {
                border-radius: 20px;
            }
            &::after {
                border-radius: 20px;
            }
        }
    }
}

svg {
    .ai-svg-wrapper {
        path {
            transition: fill .2s;
            fill: @ai-teal;
        }
        circle {
            transition: fill .2s;
            fill: @ai-teal;
        }
        polygon {
            fill: @ai-teal;
        }
        rect {
            fill: @ai-teal;
        }
    }
}

svg use svg {
    fill: inherit;
}

.loader {
	position: absolute;
	width: 80px;
	height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	.loader-svg {
		animation: rotate 3s infinite cubic-bezier(0.645, 0.045, 0.355, 1.000);
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		.path {
			stroke: @ai-teal;
			stroke-linecap: round;
			animation: dash-rotate 1.5s infinite cubic-bezier(0.645, 0.045, 0.355, 1.000);
		}
	}

    .loader-logo {
        /*transform: translate(-8%, 14%) scale(0.60);*/
        width: 48px;
        height: 35.23px;
        position: absolute;
        left: 12%;
        top: 26%;
    }

    @keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes dash-rotate {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
}

.ai-svg-hover {
    transition: background-color .2s;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0);
    font-size: 0;
    &:hover {
        background-color: @ai-teal;
        svg {
            .ai-svg-wrapper {
                .ai-svg-icn-group {
                    path, circle {
                        fill: white;
                    }
                }
            }
        }
    }
}

.ai-hover {
    position: relative;
    cursor: pointer;
    margin: 0;
    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        width: 0;
        bottom: -3px;
        left: 50%;
        background-color: @ai-teal;
        transform: translateX(-50%);
        transition: width 0.3s;
    }
    &:hover {
        &::after {
            width: 100%;
        }
    }
    &.active {
        &::after {
            width: 100% !important;
        }
    }
}

.alt-text {
    position: absolute;
    display: block;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    text-indent: 5px;
    white-space: nowrap;
}

.ai-parallax {
    position: relative;
	max-width: 100%;
	overflow: hidden;
    .parallax-item {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        height: auto;
    }
}

#page-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: @zindex-page-loading;
}

.icon-scroll {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 70px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 30px;
    opacity: 0.5;
    &:before {
        position: absolute;
        left: 50%;
        content: '';
        width: 8px;
        height: 8px;
        background: #fff;
        margin-left: -4px;
        top: 8px;
        border-radius: 4px;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scroll;
    }
    @keyframes scroll {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(46px);
        }
    }
}

.grid-container {
    position: relative;
    margin: 0 auto 20px;
    padding: 0;
    width: 100%;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    &.load-completed {
        .loader {
            display: none;
        }
    }
    .grid-sizer {
        width: 50%;
    }
    .grid-item {
        position: absolute;
        margin: 0;
        padding: 0;
        opacity: 0;
        img {
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
            height: auto;
        }
        .post-subtitle {
            display: none;
        }
        &.active {
            opacity: 1;
            cursor: pointer;
        }
        &.hide {
            display: none;
        }
        &.filter-hide {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 3%;
            background-color: @ai-teal;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: width 0.3s ease-out;
        }
        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
    /*
    @media only screen and (min-width: @screen-min) {
        .grid-item,
        .grid-sizer {
            width: 50%;
        }
    }
    @media only screen and (min-width: 1024px) {
        .grid-item,
        .grid-sizer {
            width: 33.33%;
        }
        .grid-item.double_size,
        .grid-sizer.double_size {
            width: 66.6%;
        }
    }
    @media only screen and (min-width: 1420px) {
        .grid-item,
        .grid-sizer {
            width: 33.33%;
        }
        .grid-item.double_size,
        .grid-sizer.double_size {
            width: 66.6%;
        }
    }
    @media only screen and (min-width: 1800px) {
        .grid-item,
        .grid-sizer {
            width: 25%;
        }
        .grid-item.double_size,
        .grid-sizer.double_size {
            width: 50%;
        }
    }
    */
}

.for-mobile {
    display: none;
}

.carousel-arrow {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    opacity: 0;
    cursor: pointer;
    z-index: 90;
    .icon-center {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        margin: -20px 0 0 -20px;
    }
    &.left {
        left: 0;
        .icon-center {
            transform: rotate(180deg);
        }
    }
    &.right {
        right: 0;
        .icon-center {
        }
    }
}

.float-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

body {
    .page-wrapper {
        display: none;
    }
    .page-padding {
    }
    .img-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.2s linear;
        opacity: 1;
        &:hover {
            opacity: 0;
        }
    }
    #main-logo {
        position: absolute;
        width: 66px;
        height: 66px;
        left: 30px;
        top: 20px;
        padding: 0;
        margin: 0;
        z-index: @zindex-main-logo;
        cursor: pointer;
        font-size: 0;
        a, img {
            display: block;
            width: 66px;
            height: 66px;
        }
        &.home {
            display: block;
        }
    }
    #top-bar {
        position: fixed;
        width: 100%;
        height: 80px;
        top: 0;
        left: 0;
        z-index: @zindex-top-bar;
        border-bottom: 0;
        #top-bar-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            padding: 0 30px;
            &.dark {
                background-color: rgba(26, 34, 45, 1);
            }
            .logo {
                position: relative;
                height: 39px;
                width: 206px;
                margin: 20px 0 0 72px;
                float: left;
                cursor: pointer;
                opacity: 0;
                img {
                    width: 100%;
                }
                svg {
                    .ai-svg-wrapper {
                        path {
                            fill: @ai-dark-grey; 
                        }
                    }
                }
            }
            .nav-button {
                display: none;
                position: absolute;
                width: 42px;
                height: 68px;
                top: 0;
                right: 34px;
                line-height: 17px;
                padding-top: 12px;
                cursor: pointer;
                transition: 1s;
                text-align: right;
                .line {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative; 
                    width: 70%;
                    border-top: 5px solid @ai-teal;
                    transition: .5s ease;

                    &:first-child {
                        width: 100%;
                        transform: translateY(4px);
                    }
                    &:last-child {
                        width: 40%;
                        transform: translateY(-4px);
                    }
                }
                &.open {
                    text-align: center;
                    .line {
                        left: 0;
                        &:first-child {
                            transform: translateY(17px) rotateZ(45deg);
                            width: 100%;
                        }
                        &:last-child {
                            transform: translateY(-17px) rotateZ(-45deg);
                            width: 100%;
                        }
                        &:nth-child(2) {
                            width: 1px;
                        }
                    }
                }
                @media only screen and (max-width: @screen-max) {
                    display: block;
                }
            }
            .top-menu {
                float: right;
                height: 100%;
                ul {
                    height: 100%;
                    &::before {
                      content: "";
                      display: inline-block;
                      vertical-align: middle;
                      height: 100%;
                    }
                    li {
                        display: inline-block;
                        color: @ai-dark-grey;
                        font-size: 20px;
                        font-weight: 700;
                        vertical-align: middle;
                        margin: 0 10px;
                    }
                }
                @media only screen and (max-width: @screen-max) {
                    display: none;
                }
            }
            .social-linkouts {
                width: 190px;
                height: 80px;
                float: right;
                ul {
                    width: 100%;
                    height: 80px;
                    li {
                        position: relative;
                        float: right;
                        width: 40px;
                        height: 40px;
                        margin: 20px 10px;
                        &:first-child {
                            margin-right: 0;
                        }
                        a {
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                @media only screen and (max-width: @screen-max) {
                    display: none;
                }
            }
            #filter-trigger {
                display: none;
                float: right;
                width: 30px;
                height: 30px;
                margin: 0 0 0 30px;
                cursor: pointer;
                padding: 35px 0px 15px;
                span {
                    display: block;
                }
                .grid {
                    width: 9px;
                    height: 9px;
                    color: #00aa9f;
                    background-color: #00aa9f;
                    /* Not in use when the colors are specified below */
                    transition: .5s;
                }
                &:hover {
                }

                &.close .grid {
                    transform: rotate3d(0, 0, 1, -45deg) scale3d(0.7, 0.7, 0.7);
                }

                &.rearrange .grid {
                    box-shadow: -11.25px -11.25px, 0 -11.25px, 11.25px -11.25px, -11.25px 0, 11.25px 0, -11.25px 11.25px, 0 11.25px, 11.25px 11.25px;
                }

                &.rearrange.close .grid {
                    box-shadow: 0 -9px, 0 -18px, 9px 0, -18px 0, 18px 0, -9px 0, 0 18px, 0 9px;
                }
            }
            &.home {
            }
            &.work {
                .logo {
                    opacity: 1 !important;
                }
                /*
                .social-linkouts {
                    display: none;
                }
                #filter-trigger {
                    display: inline-block;
                }
                */
            }
            &.detail {
                .logo {
                    opacity: 1 !important;
                }
                /*
                .social-linkouts {
                    display: none;
                }
                .button.close {
                    display: block;
                }
                */
            }
            &.social {
                .logo {
                    opacity: 1 !important;
                }
            }
            &.permapage {
                .logo {
                    opacity: 1 !important;
                }
                /*
                .social-linkouts {
                    display: none;
                }
                .button.close {
                    display: block;
                }
                */
            }
            @media only screen and (max-width: @screen-max) {
                & > * {
                    transform: scale(0.9);
                }
            }
        }
        &.home {
            #top-bar-wrapper {
                top: -80px;
            }
        }
    }
    #page-home {
        display: block;
        section {
            padding-top: 20px;
            padding-bottom: 20px;
            .moveup {
                opacity: 0;
            }
        }
        .button-wrapper {
            display: none;
            width: 100%;
            padding: 0;
            .button-padding {
                width: 100%;
                padding: 0 30px;
                text-align: center;
            }
        }
        .home-content {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 10px;
            @media only screen and (min-width: @screen-min) {
                width: 80%;
                max-width: 1000px;
                margin: 0 auto;
            }
        }
        button {
            &.home-button {
                font-family: @font-family-ms;
                font-weight: 400;
                color: @ai-teal;
                width: 100%;
                border: 1px solid white;
                padding: 10px 0;
                margin: 10px auto 30px;
                @media only screen and (min-width: @screen-min) {
                    display: inline-block;
                    max-width: 400px;
                }
            }
        }
        section {
            &#marquee {
                position: relative;
                overflow:hidden;
                padding-top: 0;
                padding-bottom: 0;
                #marquee-container {
                    position: relative;
                    padding: 0 30px;
                    height: 400px;
                    overflow: hidden;
                    margin-top: 0;
                    .marquee-template {
                        background-color: @ai-dark-blue;
                        position: relative;
                        box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.5);
                        margin: 15px 0;
                        height: 100%;
                        width: 100%;
                        overflow: hidden;
                        .marquee-image {
                            line-height: 0;
                            min-height: 200px;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: auto;
                            }
                            @media only screen and (max-width: @screen-max) {
                                height: 100%;
                                img {
                                    position: absolute;
                                    width: auto;
                                    height: 100%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                        }
                        .marquee-info {
                            position: absolute;
                            width: 100%;
                            bottom: 20px;
                            left: 30px;
                            text-align: left;
                            padding-bottom: 5px;
                            .marquee-title, .marquee-subtitle {
                                color: white;
                                opacity: 1;
                                transition : opacity .5s;
                            }
                            .marquee-title {
                                font-size: 36px;
                                text-transform: uppercase;
                                padding: 3px 0;
                            }
                            .marquee-subtitle {
                                font-size: 26px;
                                line-height: 1.2em;
                                margin-bottom: 10px;
                                text-transform: uppercase;
                            }
                            .marquee-title, .marquee-subtitle {
                                opacity: 0;
                            }
                            &.active {
                                .marquee-title, .marquee-subtitle {
                                    opacity: 1;
                                }
                            }
                            @media only screen and (max-width: @screen-max) {
                                left: 0;
                                text-align: center;
                                .marquee-title, .marquee-subtitle {
                                    display: none;
                                }
                                .btn-1 {
                                    display: inline-block;
                                    width: 320px;
                                    height: 70px;
                                    font-size: 28px;
                                    line-height: 70px;
                                    bottom: 40px;
                                    &.round {
                                        &::before {
                                            border-radius: 40px;
                                        }
                                        &::after {
                                            border-radius: 40px;
                                        }
                                    }
                                }
                            }


                            @media only screen and (max-width: @screen-max) {
                                .btn-1 {
                                    bottom: 0;
                                }
                            }
                        }
                        /*
                        @media only screen and (max-width: @screen-max) {
                            top: 50px;
                            opacity: 0;
                            &.loaded {
                                top: 0;
                                opacity: 1;
                            }
                        }
                        */
                    }
                    .marquee-controls {
                        display: none;
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                        text-align: center;
                        .marquee-control-button {
							display: inline-block;
							width: 30px;
							overflow:hidden;
							font-size: 90px;
							height: 80px;
							line-height: 80px;
							cursor: pointer;
							color: @ai-light-grey;
                            transition : color 200ms ease-out;
							&.active {
								color: @ai-dark-grey;
							}
							&:hover {
								color: @ai-dark-grey;
							}
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    &.carousel-container {
                        padding: 0;
                        width: 100%;
                        .marquee-template {
                            display: none;
                            position: absolute;
                            box-shadow: none;
                            margin: 0;
                            width: 100%;
                            //&:first-child {
                            //    display: block;
                            //}
                        }
                        .marquee-controls {
                            display: block;
                            @media only screen and (max-width: @screen-max) {
                                display: none;
                            }
                        }
                    }
                }
                .carousel-arrow {
                    display: block;
                    width: 36px;
                    height: 36px;
                    top: auto;
                    margin-top: auto;
                    opacity: 1;
                    border-radius: 35px;
                    border: 2px solid #00aa9f;
                    transition: 0.25s background;
                    .icon-center {
                        position: absolute;
                        width: 36px;
                        height: 36px;
                        top: 50%;
                        left: 50%;
                        margin: -18px 0 0 -18px;
                    }
                    &.left {
                        left: 30px;
                        top: 50%;

                        margin-top: -18px;
                    }
                    &.right {
                        right: 30px;
                        top: 50%;

                        margin-top: -18px;
                    }
                    @media only screen and (max-width: @screen-max) {
                        display: block;
                        width: 70px;
                        height: 70px;
                        bottom: 30px;
                        .icon-center {
                            width: 70px;
                            height: 70px;
                            margin: -35px 0 0 -35px;
                        }
                        &.left {
                            left: 50%;
                            margin-left: -260px;
                            top: auto;
                        }
                        &.right {
                            right: 50%;
                            margin-right: -260px;
                            top: auto;
                        }
                    }
                    &:hover {
                        background-color: #00aa9f;
                        .ai-arrow-triangle {
                            fill: white;
                        }
                    }
                }
            }
            &.static-mode {
                #marquee-container {
                    height: auto !important;
                }
                .button-wrapper {
                    display: block;
                }
            }
            &#about-hello {
                position: relative;
                background-color: white;
                padding-top: 0;
                padding-bottom: 0;
                h1 {
                    position: relative;
                    padding: 0;
                    font-size: 0;
                    width: 100%;
                    padding: 5% 30px;
                    img {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                    }
                    &.hello-from-ai {
                        padding: 0;
                        text-align: center;
                        overflow: hidden;
                        img {
                            width: 100%;
                            min-width: 980px;
                            margin: 0 auto;
                            left: 50%;
							transform: translateX(-50%);
                            &#img-hello-off {
                                position: relative;
                            }
                            &#img-hello-on {
                                position: absolute;
                                top: 0;
                                opacity: 0;
                                &.start-neon {
                                    &#img-hello-on {
                                        animation: flicker 5s forwards 0.3s step-end;
                                    }
                                }
                            }
                        }
                    }
                }
                .h2 {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 1920/960*5vw;
                }
                @media only screen and (max-width: @screen-max) {
                    h1 {
                        margin: 0 auto;
                        img {
                            &#img-who-we-are {
                                margin: 20px auto 30px;
                                width: 70%;
                            }
                        }
                    }
                }
                @keyframes flicker {
                    0%    { opacity: 1;   }
                    3%    { opacity: 0.4; }
                    6%    { opacity: 1;   }
                    7%    { opacity: 0.4; }
                    8%    { opacity: 1;   }
                    9%    { opacity: 0.4; }
                    10%   { opacity: 1;   }
                    100%  { opacity: 1;   }
                }
            }
            &#about-who-we-are {
                background-color: white;
                .who-are-we-canvas {
                    width: 100%;
                    .backdrop-wrapper {
                        position: relative;
                        width: 344px;
                        height: 584px;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        margin: -100px 0 -200px;
                        transform-origin: left center;
                        .backdrop {
                            position: absolute;
                            width: 141px;
                            height: 141px;
                            top: 0;
                            left: 0;
                            transform: scale(2) translate(11%, 10%);
                            transform-origin: left top;
                            transition: transform 3s;
                        }
                        .backdrop-bg {
                            position: absolute;
                            width: 1165px;
                            height: 355px;
                            left: 50%;
                            top: 50%;
                            transform: scale(0.15) translate(-50%, -50%);
                            transform-origin: left top;
                            transition: transform 3s;
                        }
                        .backdrop-costume {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            .backdrop {
                                overflow: hidden;
                                border-radius: 50%;
                                filter: blur(4px);
                                background-color: white;
                            }
                            .img-costume {
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                transform: scale(2) rotate(-30deg) translate(-32%, 10.6%);
                                transform-origin: left top;
                                transition: transform 3s;
                            }
                            .backdrop-bg-mimic {
                                position: absolute;
                                width: 1165px;
                                height: 355px;
                                left: 50%;
                                top: 50%;
                                transform: scale(0.15) translate(-50%, -50%);
                                transform-origin: left top;
                                transition: transform 3s;
                            }
                        }
                    }
                }
            }
            &#about-individually-we-are {
                position: relative;
                background-color: @ai-teal;
                background-image: url('../images/wave_top.jpg');
                background-position: left top;
                background-repeat: repeat-x;
                padding: 60px 0 0;
                overflow: visible;
                &.start {
                    animation: bg-scroll 20s linear infinite;
                }
                .image-comp {
                    position: absolute;
                    transform: scale(0.5);
                    transform-origin: left top;
                    &.icon-idea {
                        top: 10px;
                        left: -130px;
                    }
                    &.icon-rod {
                        bottom: -70px;
                        left: -70px;
                    }
                    &.icon-pen {
                        top: -40px;
                        right: -220px;
                    }
                    &.icon-mag {
                        bottom: -100px;
                        right: -115px;
                    }
                    @media only screen and (max-width: 950px) {
                        display: none;
                    }
                }
                #bubbles {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    .bubble {
                        position: absolute;
                        width: 256/1920*100vw;
                        height: 256/1920*100vw;
                        will-change: transform;
                        animation: bubblerise 2s forwards linear;
                        opacity: 0;
                        .bubblewobble {
                            //position
                            width: 100%;
                            height: 100%;
                            background-image: url('../images/bubble.png');
                            background-size: cover;
                            background-position: center center;
                            background-repeat: no-repeat;
                            /*
                            will-change: transform;
                            animation: bubblewobble 0.44s infinite linear;
                            */
                        }
                    }
                    @keyframes bubblerise {
                        0% {
                            bottom: -20%;
                            opacity: 0;
                        }
                        25% {
                            bottom: 10%;
                            opacity: 0.8;
                        }
                        50% {
                            bottom: 40%;
                            opacity: 1;
                        }
                        75% {
                            bottom: 70%;
                            opacity: 0.8;
                        }
                        100% {
                            bottom: 100%;
                            opacity: 0;
                        }
                    }
                    @keyframes bubblewobble {
						0% {
							margin-left: 0;
						}
						50% {
							margin-left: 4px;
						}
					}
                }
                .name-changer {
                    position: relative;
                    width: 657px;
                    height: 160px;
                    margin: 20px auto;
                    background-image: url('../images/dotted_frame.png');
                    background-size: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    .name-changer-wrapper {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        overflow: hidden;
                        .name-changer-item {
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 657px;
                            height: 160px;
                            line-height: 160px;
                            font-size: 80px;
                            color: white;
                            text-align: center;
                            img {
                                height: 80%;
                                width: auto;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                    @media only screen and (max-width: 720px) {
                        width: 80%;
                        margin: 0 10%;
                        .name-changer-wrapper {
                            .name-changer-item {
                                width: 100%;
                                img {
                                    width: 60%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
            &#about-together {
                text-align: center;
                padding-top: 0;
                background-color: @ai-dark-blue;
                background-image: url('../images/together_bg.jpg');
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                img {
                    display: inline-block;
                    transform: scale(0.6);
                }
                h1 {
                    margin-top: -60px;
                }
                .wave-bottom-top {
                    background-image: url('../images/wave_bottom_top.png');
                    background-position: left top;
                    background-repeat: repeat-x;
                    padding: 0;
                    margin: 0;
                    margin-top: -1px;
                }
                .wave-bottom-bottom {
                    background-image: url('../images/wave_bottom_bottom.png');
                    background-position: left top;
                    background-repeat: repeat-x;
                    padding: 120px 0 30px;
                }
                &.start {
                    .wave-bottom-top {
                        animation: bg-scroll-right 20s linear infinite;
                    }
                    .wave-bottom-bottom {
                        animation: bg-scroll 20s linear infinite;
                    }
                }
            }
            &#about-we-are-fans {
                position: relative;
                padding: 0;
                video {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: auto;
                }
                h1 {
                    width: 612px;
                }
                @media only screen and (max-width: 620px) {
                    h1 {
                        width: 100%;
                    }
                }
            }
            &#about-our-clients {
                background-color: white;
                padding-bottom: 0;
                ul {
                    list-style: none outside none;
                    width: 100%;
                    margin: 20px auto 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    flex-flow: row wrap;
                    align-content: flex-end;

                    li {
                        width: 30%;
                        margin-bottom: 20px;
                        opacity: 0;
                        div {
                            margin: 0 auto;
                        }
                    }
                    @media only screen and (min-width: @screen-min) {
                        max-width: 1000px;
                        padding: 40px 0 0;
                        li {
                            margin-bottom: 60px
                        }
                    }
                    @media only screen and (min-width: 1024px) {
                        li {
                            width: 20%;
                        }
                    }
                    @media only screen and (min-width: 1420px) {
                    }
                    @media only screen and (min-width: 1800px) {
                    }
                }
            }
            &#about-our-approach {
                padding-top: 100px;
                background-color: white;
                h1 {
                    color: @ai-teal;
                }
                .our-approach-image {
                    display: block;
                    float: right;
                    height: 500px;
                    margin-top: -100px;
                }

                #speak-with-them {
                    padding: 50px 30px 80px;
                }
                @media only screen and (max-width: @screen-max) {
                    #speak-with-them {
                        padding: 50px 30px 50px;
                    }
                }
                @media only screen and (max-width: 1100px) {
                    .our-approach-image {
                        float: none;
                        margin: 30px auto 0;
                    }
                    p {
                        text-align: center;
                    }
                }
            }
            &#about-contact {
                background-color: white;
                margin-top: -1px;
                #love-bubble {
                    display: inline-block;
                    height: 80px;
                    width: auto;
                    margin: 0 10px;
                    vertical-align: middle;
                    overflow: visible;
                    svg {
                        height: 100%;
                        width: auto;
                        overflow: visible;
                    }
                }
            }
            &#about-recent-works {
                background-color: white;
                padding-top: 0;
                padding-bottom: 0;
                text-align: center;
                h3 {
                    padding: 30px 0;
                    background-color: white;
                    margin: 0 auto;
                }
                .grid-container {
                    margin-bottom: 0;
                    .grid-item {
                        &::after {
                            height: 8px;
                        }
                    }
                    @media only screen and (min-width: 1800px) {
                        .grid-item,
                        .grid-sizer {
                            width: 16.66%;
                        }
                        .grid-item.double_size,
                        .grid-sizer.double_size {
                            width: 33.33%;
                        }
                    }
                }
                .btn-1 {
                    display: inline-block;
                    margin: 30px auto;

                }
                @media only screen and (max-width: @screen-max) {
                    .btn-1 {
                        width: 320px;
                        height: 70px;
                        font-size: 28px;
                        line-height: 70px;
                        &.round {
                            &::before {
                                border-radius: 40px;
                            }
                            &::after {
                                border-radius: 40px;
                            }
                        }
                    }
                }
            }
            &#social {
                height: 400px;
                position: relative;
                background-color: @ai-dark-blue;
                padding: 0;
                min-height: 200px;
                overflow: hidden;
                h1 {
                    color: white;
                }
                .button-wrapper {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    height: 400px;
                    background-image: url(../images/blue_fade_cover.png);
                    background-position: center bottom;
                    background-repeat: repeat-x;
                    z-index: 10;
                    .button-padding {
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
            &#home-contact {
                padding: 0 0 80px;
                overflow: hidden;
                h1 {
                    font-size: 40px; 
                    margin-bottom: 50px;
                }
                #ai-logo-contact {
                    opacity: 0;
                    width: 100%;
                    height: 550px;
                    padding-top: 50px;
                    svg {
                        height: 100%;
                        width: auto;
                        path {
                            fill: white;
                        }
                    }
                }
                .contact-info-row {
                    &.same-width {
                        width: 426px;
                    }
                }
                #paper-plane-bg {
                    display: none;
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    width: 960px;
                    transform: translateX(-70%);
                    z-index: 0;
                    pointer-events: none;
                    svg {
                        overflow: visible;
                        width: 100%;
                    }
                }
                @media only screen and (max-width: 1166px) {
                    #paper-plane-bg {
                        top: 170px;
                        width: 600px;
                        transform: translateX(-97%);
                    }
                    .contact-info-row {
                        margin: 10px auto;
                        &.same-width {
                            width: 95%;
                        }
                    }
                }
            }
        }
        @keyframes bg-scroll {
            from {
                background-position: 0 0;
            }
            to {
                background-position: -340px 0;
            }
        }
        @keyframes bg-scroll-right {
            from {
                background-position: 0 0;
            }
            to {
                background-position: 340px 0;
            }
        }
    }
    #page-work {
        background-color: @ai-dark-blue;
        padding-top: 80+64px;
        width: 100%;
        height: 100%;
        .loader {
            position: fixed;
        }
        .grid-container {
            margin: 0 auto;
        }
        #filter {
            position: fixed;
            top: 80px;
            left: 0;
            height: 64px;
            font-family: @font-family-ms;
            font-weight: 400;
            font-size: 19px;
            z-index: @zindex-filter;
            width: 100%;
            background-color: @ai-dark-blue-08;
            ul {
                padding-top: 17px;
                text-align: center;
                li {
                    display: inline-block;
                    color: white;
                    margin: 0 2px;
                    padding: 4px 0;
                }
            }
        }
    }
    #page-social {
        background-color: @ai-dark-blue;
        padding-top: 80px;
        article {
            cursor: pointer;
        }
        .grid-container {
            margin: 0 auto;
        }
    }
}

#page-job {
    background-color: @ai-dark-blue;
    padding-top: 80+64px;
    width: 100%;
    height: 100%;
    color: @ai-ivory;
    h2 {
        margin: 0 auto 2em;
        text-align: center;
    }
    .above-title {
        margin: 0;
        color: @ai-teal;
    }
    h3 {
        margin: 0 auto 3em;
        font-size: 1em;
        @media only screen and (max-width: @screen-max) {
            margin-bottom: 2em;
            font-size: 30px;
        }
    }
    h4 {
        margin: 2em auto 0;
        font-size: 1em;
        @media only screen and (max-width: @screen-max) {
            font-size: 30px;
        }
    }
    p {
        margin: 0 auto 1em;
        line-height: 1.5;
    }

    article {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 40px;
        @media only screen and (min-width: @screen-min) {
            width: 80%;
            max-width: 1000px;
            margin: 0 auto;
        }
    }

    ul {
        margin: 0 auto 1em;
        line-height: 1.5;
        list-style: disc;
        padding-left: 20px;
        @media only screen and (max-width: @screen-max) {
            margin-bottom: 2em;
            font-size: 30px;
        }
    }
}

#page-covid19, #page-services {
    padding-top: 80+64px;
    width: 100%;
    height: 100%;
    color: @ai-dark-grey;
    h2 {
        margin: 0 auto 2em;
        text-align: center;
    }
    h3 {
        margin: 0 auto;
        font-size: 2em;
        text-align: center;
        @media only screen and (max-width: @screen-max) {
            margin-bottom: 2em;
            font-size: 30px;
        }
    }
    h4 {
        margin: 3em auto 1em;
        font-size: 1.3em;
        //color: @ai-teal;
        @media only screen and (max-width: @screen-max) {
            font-size: 30px;
        }
    }
    h5 {
        font-size: 1.1em;
        margin: 2em auto 0;
        color: @ai-teal;
    }
    p {
        margin: 0 auto 1em;
        line-height: 1.5;
        &.sub-title {
            text-align: center;
            font-style: italic;
            margin-bottom: 2em;
        }
    }
    section {
        margin-bottom: 4em;
    }

    article {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 40px;
        @media only screen and (min-width: @screen-min) {
            width: 80%;
            max-width: 1000px;
            margin: 0 auto;
        }
    }

    ul {
        margin: 0 auto 1em;
        line-height: 1.5;
        list-style: disc;
        padding-left: 20px;
        @media only screen and (max-width: @screen-max) {
            margin-bottom: 2em;
            font-size: 30px;
        }
    }
}

#page-services {
    h5 {
        margin: 1.25em auto 0.25em;
    }
}

.contact {
    position: relative;
    width: 100%;
    padding: 50px 0;
    background-color: @ai-dark-blue;
    border: none;
    &.compact {
        height: 100px;
    }
    .contact-row {
        margin: 50px auto !important;
    }
    .contact-info {
        display: inline-block;
        text-align: center;
        margin: 0 auto;
        vertical-align: top;
        .contact-info-title {
            display: block;
            color: @ai-teal;
            font-size: 16px;
            margin-bottom: 20px;
            text-align: left;
            &.opps {
                margin-top: -30px;
                margin-bottom: 10px;
            }
        }
        .contact-info-row {
            display: block;
            text-align: left;
            .contact-info-icon {
                display: inline-block;
                vertical-align: middle;
                width: 40px;
                svg {
                    width: 100%;
                }
            }
            .contact-info-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 22px;
                line-height: 1.4em;
                color: white;
                padding-left: 20px;
                text-align: left;
                span {
                    display: block;
                }
                a, span, p {
                    color: white;
                    font-size: 22px;
                }
                &.higher {
                    height: 91px;
                }
            }
        }
        .social-linkouts {
            li {
                position: relative;
                display: inline-block;
                width: 50px;
                height: 50px;
                margin: 0 10px;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        @media only screen and (max-width: 1166px) {
            width: 100%;
            position: relative;
            .contact-info-title {
                text-align: center;
                font-size: 26px;
            }
            .contact-info-row {
                text-align: center;
                .contact-info-icon {
                    width: 60px;
                }
                .contact-info-content {
                    font-size: 30px;
                    width: 75%;
                    a, span, p {
                        font-size: 30px;
                    }
                    &.higher {
                        height: auto;
                    }
                    &.mobile-higher {
                        height: 150px;
                    }
                }
            }
            .social-linkouts {
                li {
                    position: relative;
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    margin: 0 10px;
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .copyright {
        font-size: 12px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        color: @ai-teal;
        transform: translateX(-50%);
    }
}

.overlay {
    display: none;
    width: 100%;
    z-index: @zindex-overlay;
	background: black;
	opacity: 0;
    overflow: auto;
    .padding-container {
        width: 100%;
        height: 100%;
        padding-top: 80px;
    }
}

#mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: @zindex-nav;
    overflow: hidden;
    background-color: @ai-ivory;
    ul {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 75%;
        li {
            position: relative;
            text-align: center;
            color: @ai-dark-grey;
            cursor: pointer;
            font-size: 40px;
            font-weight: 700;
            padding: 40px 40px;
            &.bottom-divider {
                border-bottom: 2px solid @ai-teal;
            }
        }
    }
    .mobile-social-linkouts {
        width: 100%;
        height: 80px;
        ul {
            width: 100%;
            height: 80px;
            li {
                position: relative;
                display: inline-block;
                width: 30px;
                height: 30px;
                margin: 10px 10px;
                &:first-child {
                    margin-right: 0;
                }
                &:last-child {
                    margin-left: 0;
                }
                a {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@import "workDetail";
@import "assets";

body {
    &.mobile {
        button {
            &.btn-1 {
                &:hover {
                    span {
                        color: @ai-teal;
                        a {
                            color: @ai-teal !important;
                        }
                    }
                    &::before {
                        opacity: 0 !important;
                        transform: scale(1, 0.1) !important;
                    }
                    &::after {
                        transform: scale(1, 1) !important;
                        opacity: 1 !important;
                    }
                }
            }
        }
        .ai-svg-hover {
            &:hover {
                background-color: transparent;
                svg {
                    .ai-svg-wrapper {
                        .ai-svg-icn-group {
                            path, circle {
                                fill: @ai-teal;
                            }
                        }
                    }
                }
            }
        }
        .ai-hover {
            &:hover {
                &::after {
                    width: 0;
                }
            }
        }
        .grid-container {
            .grid-item {
                &:hover {
                    &::after {
                        width: 0;
                    }
                }
            }
        }
        .img-hover {
            &:hover {
                opacity: 1;
            }
        }
        section {
            &#marquee {
                #marquee-container {
                    .marquee-controls {
                        .marquee-control-button {
							&:hover {
								color: @ai-light-grey;
							}
                        }
                    }
                }
            }
        }
    }
}
